const Index = () => import('@/pages/profile/settings/Index')
const Personal = () => import('@/pages/profile/settings/Personal')
const AnggotaKeluarga = () => import('@/pages/profile/settings/AnggotaKeluarga')
const AddAnggotaKeluarga = () => import('@/pages/profile/settings/AddAnggotaKeluarga')
const EditAnggotaKeluarga = () => import('@/pages/profile/settings/EditAnggotaKeluarga')
const Contact = () => import('@/pages/profile/settings/Contact')
const Password = () => import('@/pages/profile/settings/Password')

export default [
  {
    path: 'setting',
    name: 'Setting',
    component: Index
  },
  {
    path: 'setting/personal-data',
    name: 'SettingPersonalData',
    component: Personal,
    meta: {
      noProfileCard: true
    }
  },
  {
    path: 'setting/password',
    name: 'SettingPassword',
    component: Password,
    meta: {
      noProfileCard: true
    }
  },
  {
    path: 'setting/contact',
    name: 'SettingContact',
    component: Contact,
    meta: {
      noProfileCard: true
    }
  },
  {
    path: 'setting/anggota-keluarga',
    name: 'SettingAnggotaKeluarga',
    component: AnggotaKeluarga,
    meta: {
      noProfileCard: true
    }
  },
  {
    path: 'setting/tambah-anggota-keluarga',
    name: 'SettingAddAnggotaKeluarga',
    component: AddAnggotaKeluarga,
    meta: {
      noProfileCard: true
    }
  },
  {
    path: 'setting/edit-anggota-keluarga/:pas_id',
    name: 'SettingEditAnggotaKeluarga',
    component: EditAnggotaKeluarga,
    meta: {
      noProfileCard: true
    }
  }
]
