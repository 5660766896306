<template>
  <div class="container">
    <app-bar
      title="Semua Voucher & Partnership"
      :previousLink="{ name: 'Home' }"
    />
    <Loading v-if="loading.getVoucherDetail" />
    <!-- <Empty v-else-if="vouchers.length < 1" :message="'Anda tidak memiliki voucher apapun.'"/> -->
    <div v-else>
      <div class="pt-md-4 px-3 px-md-2 mb-3">
        <ul class="nav nav-tabs d-flex">
          <li class="nav-item flex-fill text-center" role="button">
            <a class="nav-link" :class="{'active': filter === 'voucher'}" @click="filter = 'voucher'">Voucher Saya</a>
          </li>
          <li class="nav-item flex-fill text-center" role="button">
            <a class="nav-link" :class="{'active': filter === 'partnership'}" @click="filter = 'partnership'">Partnership</a>
          </li>
        </ul>
      </div>

      <div
        v-if="filter === 'voucher'"
        class="item-content d-flex flex-column px-2 px-md-0 mb-3 mb-md-0">
        <div
          v-for="voucher in vouchers"
          :key="voucher.id"
          class="container-voucher d-flex justify-content-between align-items-center p-2 ps-0"
          @click="openDetail(voucher)"
          role="button"
        >
          <div class="d-flex align-items-center gap-2">
            <div>
              <img :src="voucher.gambar" :alt="voucher.even">
            </div>
            <div class="description">
              <span>{{ voucher.even }}</span>
              <table class="text-muted fw-light">
                <tr>
                  <td>Berakhir Pada</td>
                  <td>: {{ extractDate(voucher.tgl_berakhir_voucher) }}</td>
                </tr>
                <tr>
                  <td>Diklaim Pada</td>
                  <td>: {{ extractDate(voucher.tgl_klaim) }}</td>
                </tr>
                <tr>
                  <td>Diredeem Pada</td>
                  <td>: {{ extractDate(voucher.tgl_redeem) }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div>
            <button class="btn color-primary-500 rounded-pill fs-10">
              Pakai
            </button>
          </div>
        </div>

        <div class="bottom-bar d-flex justify-content-center p-3 border-0">
          <button class="btn color-primary-500 rounded-pill w-100">
            Tambahkan Voucher
          </button>
        </div>
      </div>

      <div
        v-if="filter === 'partnership'"
        class="item-content d-flex flex-column px-2 px-md-0 mb-3 mb-md-0"
      >
        <div
          v-for="partnership in partnerships"
          :key="partnership.idpromosi"
          class="container-partnership"
        >
          <div class="row">
            <img :src="partnership.image_promo" :alt="partnership.judul_promo">
          </div>
          <div class="d-flex container-partnership-description justify-content-around">
            <div>
              <span>{{ partnership.diskon }}</span>
            </div>
            <div>
              <span>{{ partnership.berlaku }}</span>
            </div>
          </div>
          <div class="row">
            <span>{{ partnership.judul_promo }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// mandatory
// import Empty from '@/components/utility/Empty'
import AppBar from '@/components/AppBar'
import Loading from '@/components/utility/Loading'
import { onMounted, ref } from 'vue'
import { showNotif } from '@/core/utility'
import { useRouter } from 'vue-router'

// data
import { loading, vouchers, voucherDetailExternal, getVouchers } from '@/core/voucher'
import { loading as loadingPartnership, partnerships, getPartnerships } from '@/core/partnership'

export default {
  components: {
    // Empty,
    AppBar,
    Loading
  },
  setup () {
    const filter = ref('voucher')
    const router = useRouter()

    const extractDate = (dateTime) => {
      if (!dateTime) {
        return '-'
      }

      const formatted = new Date(dateTime)
      if (!formatted) {
        return '-'
      }

      return formatted.toLocaleDateString('id-ID', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      })
    }

    const openDetail = (voucher) => {
      if (!voucher) {
        return showNotif({
          status: 'error',
          message: 'Data voucher tidak valid'
        })
      }

      if (!voucher.info_detail) {
        return showNotif({
          status: 'error',
          message: 'Detail voucher tidak tersedia'
        })
      }

      voucherDetailExternal.value = voucher

      return router.push({
        name: 'VoucherDetailExternal'
      })
    }

    onMounted(async () => {
      await getVouchers()
      await getPartnerships()
    })

    return {
      loading,
      vouchers,
      filter,
      extractDate,
      openDetail,
      partnerships,
      loadingPartnership
    }
  }
}
</script>

<style lang="scss" scoped>
.container-voucher {
  border-radius: 15px;
  border: 1px solid #dee2e6;

  &::before{
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    background: white;
    border-radius: 50%;
    border-right: 1px solid #dee2e6;
    margin-left: -15px;
  }

  .description {
    span {
      font-size: 12px;

      @media (min-width: 425px) {
        font-size: 14px;
      }
    }

    table {
      font-size: 8px;

      @media (min-width: 425px) {
        font-size: 12px;
      }
    }
  }

  img {
    width: 100px;
    height: auto;
  }
}

.container-partnership {
  border-radius: 15px;
  border: 1px solid #dee2e6;

  .container-partnership-description {
    border-radius: 15px;
    border: 1px solid #dee2e6;
  }
}

.btn {
  border: 1px solid #dee2e6;
  padding: 10px 30px;
}
</style>
