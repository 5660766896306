<template>
  <div class="main-page">
    <div class="container">
      <app-bar
        title="Data Pasien"
        previousLink="/" />

      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-6">
              <div class="col py-2">
                <label class="fw-400 fs-12 lh-14 color-neutral-600">Nama Pasien</label>
                <div class="input-group mb-3">
                  <router-link
                    class="w-100"
                    :to="{
                      query: { showPatient: !isPatientForMobileShown }
                    }">
                    <select
                      class="form-select select-patient color-neutral-900"
                      id="input-patient"
                      v-model="selectedMember.nama"
                      name="patient"
                      rules="required">
                        <option selected disabled style="display: none; border: none">Pilih Pasien</option>
                    </select>
                  </router-link>
                </div>
                <div
                  class="initial d-flex align-items-center bg-neutral-100 mb-2">
                  <div class="initial-wrapper d-flex align-items-center justify-content-center bg-info-500 rounded-circle">
                    <span class="initial-value fw-400 fs-14 lh-18 text-white">
                      {{ getInitialName(selectedMember.nama) }}
                    </span>
                  </div>
                  <div class="value-wrapper d-flex flex-column">
                    <span class="value-wrapper-name fw-700 fs-14 lh-18 color-neutral-900">{{ selectedMember.nama }}</span>
                    <label class="value-wrapper-email fw-400 fs-12 lh-14 color-neutral-600">{{ selectedMember.nama_hubungan }} ({{ selectedMember.nowa }})</label>
                  </div>
                </div>
                <div class="input-group">
                  <div class="form-check d-flex align-items-center">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="exampleCheck1"
                      v-model="useMyData"
                      @change="setMyData"
                    >
                    <label class="form-label pt-1 ms-2 mb-0 fw-400 fs-14 lh-18 color-neutral-900" for="exampleCheck1">Gunakan data saya</label>
                  </div>
                </div>
              </div>
                <!-- <div class="col py-2">
                  <router-link
                    class="btn btn-add bg-blue-soft-pastel p-2 w-100 text-primary m-0 fm-nunito fst-normal fw-600 fs-14 lh-19"
                    data-bs-toggle="modal123"
                    data-bs-target="#modalAddPatient"
                    :to="{
                      query: { modalPatient: 'show' }
                    }"
                  >
                    Saya ingin daftarkan orang lain
                  </router-link>
                </div> -->

              <div class="col py-2">
                <label class="fw-400 fs-12 lh-14 color-neutral-600">Tanggal Periksa</label>
                <div class="input-group row-flex justify-content-between gap-1">
                  <div class="col-3">
                    <Field
                      type="number"
                      v-model="date.tgl"
                      name="tgllahir"
                      rules="required"
                      class="form-control"
                      min="1"
                      placeholder="Tanggal"
                    />
                  </div>
                  <div class="col-5">
                    <select
                      class="form-select select-month color-neutral-900 border-0"
                      id="input-month"
                      v-model="date.bln"
                      name="bulan"
                      rules="required">
                        <option selected disabled value="">Pilih Bulan</option>
                        <option value="01">Januari</option>
                        <option value="02">Februari</option>
                        <option value="03">Maret</option>
                        <option value="04">April</option>
                        <option value="05">Mei</option>
                        <option value="06">Juni</option>
                        <option value="07">Juli</option>
                        <option value="08">Agustus</option>
                        <option value="09">September</option>
                        <option value="10">Oktober</option>
                        <option value="11">November</option>
                        <option value="12">Desember</option>
                    </select>
                  </div>
                  <div class="col-3">
                    <Field
                      type="number"
                      v-model="date.thn"
                      name="tahun"
                      rules="required"
                      max="4"
                      class="form-control"
                      placeholder="Tahun"
                    />
                  </div>
                  <ErrorMessage name="tgllahir" class="invalid-feedback" />
                </div>
                <!-- <input type="date" v-model="cart.tgl_periksa" class="form-control form-input fm-nunito fst-normal fw-normal fs-12 bg-form-input pt-3 pb-3 ps-3 pe-2 border-white" required> -->
              </div>

              <div class="col py-2">
                <label class="fw-400 fs-12 lh-14 color-neutral-600 mb-2">Metode Pembayaran</label>
                <div class="d-flex align-items-center gap-2">
                  <input
                    class="payment-method"
                    type="radio"
                    id="onlinePayment"
                    @click="cart.status_bayar = 0"
                    :checked="cart.status_bayar === 0"
                    disabled>
                  <label
                    class="payment-method-label fw-400 fs-14 lh-18 color-neutral-400"
                    for="onlinePayment">
                    Bayar Online
                  </label>
                </div>
                <!-- <div class="w-50">
                    <div class="ps-2 d-flex justify-content-end">
                      <div
                        @click="cart.status_bayar = 1"
                        class="btn btn-gender fm-nunito fst-normal fw-600 fs-12 lh-16"
                        :class="{ 'active':cart.status_bayar === 1 }"
                      >
                        Pembayaran Offline
                      </div>
                    </div>
                  </div> -->
              </div>

              <div class="col py-2">
                <label class="fw-400 fs-12 lh-14 color-neutral-600 mb-2">Anda membutuhkan hasil print-out?</label>
                <div class="d-flex align-items-center gap-4">
                  <div class="d-flex align-items-center gap-2">
                    <input
                      class="payment-method"
                      type="radio"
                      id="printOut"
                      @click="handlePrintOut(1)"
                      :checked="cart.print_out === 1">
                    <label
                      class="payment-method-label fw-400 fs-14 lh-18 color-neutral-700"
                      for="printOut">
                      Ya
                    </label>
                  </div>
                  <div class="d-flex align-items-center gap-2">
                    <input
                      class="payment-method"
                      type="radio"
                      id="printOut"
                      @click="handlePrintOut(0)"
                      :checked="cart.print_out === 0">
                    <label
                      class="payment-method-label fw-400 fs-14 lh-18 color-neutral-700"
                      for="printOut">
                      Tidak
                    </label>
                  </div>
                </div>
              </div>

              <div v-if="cart.print_out === 1" class="col py-2">
                <label class="fw-400 fs-12 lh-14 color-neutral-600 mb-2">
                  Laporan hasil pemeriksaan akan :
                </label>
                <div class="d-flex align-items-center gap-4">
                  <div class="d-flex align-items-center gap-2">
                    <input
                      class="payment-method"
                      type="radio"
                      id="printOutReport"
                      @click="handlePengiriman(0)"
                      :checked="cart.dikirim_status === 0">
                    <label
                      class="payment-method-label fw-400 fs-14 lh-18 color-neutral-700"
                      for="printOutReport">
                      Diambil Sendiri
                    </label>
                  </div>
                  <div class="d-flex align-items-center gap-2">
                    <input
                      class="payment-method"
                      type="radio"
                      id="printOutReport"
                      @click="handlePengiriman(1)"
                      :checked="cart.dikirim_status === 1">
                    <label
                      class="payment-method-label fw-400 fs-14 lh-18 color-neutral-700"
                      for="printOutReport">
                      Dikirimkan
                    </label>
                  </div>
                </div>
              </div>

              <!-- Notifikasi Ongkos Kirim -->
              <div v-if="cart.print_out === 1 && cart.dikirim_status === 1" class="col py-2">
                <div class="notification bg-info-50 d-flex align-items-center gap-3">
                  <div class="notification-icon">
                    <img
                      src="@/assets/icon/discount-shape.svg"
                      alt=""
                    >
                  </div>
                  <span class="notification-desc fw-400 fs-12 lh-14 color-info-500">
                    Jika DIKIRIMKAN, maka akan dikenakan biaya tambahan sebesar Rp. 15.000
                  </span>
                </div>
              </div>

              <div v-if="cart.print_out === 1 && cart.dikirim_status === 1" class="col py-2">
                <label class="fw-400 fs-12 lh-14 color-neutral-600 mb-2">
                    Alamat Pengiriman
                </label>
                <textarea
                  v-model="cart.dikirim_alamat"
                  name="alamat"
                  class="form-control input-note border"
                  placeholder="Alamat Pengiriman"
                ></textarea>
              </div>
              <!-- <div
                class="col py-2 px-3 bg-blue-soft-pastel rounded-xl my-2">
                <div class="row justify-content-between">
                  <div class="col d-flex align-items-center">
                    <span class="fw-bold">Anamnesa</span>
                  </div>
                  <div class="col d-flex justify-content-end">
                    <button
                      class="btn btn-warning p-2 text-white w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#modalAnamnesa"
                    >
                      {{ (statusAnamnesa) ? 'Lihat':'Isi Form' }}
                    </button>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="col-md-6 pb-5 pb-md-0 mb-5 mb-md-0">
              <div class="col py-2">
                <label class="fw-500 color-neutral-900 mb-2">Catatan</label>
                <textarea
                  v-model="cart.ap_catatan"
                  name="note"
                  class="form-control input-note border"
                  placeholder="Tambah catatan..." />
              </div>
              <div class="col">
                <div class="d-flex align-items-start justify-content-between mb-3">
                  <span class="fw-400 fs-14 lh-24 color-neutral-700">Total Pembayaran</span>
                  <div class="total-payment d-flex flex-column align-items-end gap-1">
                    <span class="fw-700 fs-14 lh-24 color-neutral-900">
                      Rp. {{ total.toLocaleString('id-ID') }}
                    </span>
                    <!-- <s class="fw-400 fs-12 lh-16 color-neutral-600">Rp. 356.000</s> -->
                  </div>
                </div>
              </div>

              <!-- Notifikasi Diskon -->
              <div v-if="diskon" class="col">
                <div class="notification bg-info-50 d-flex align-items-center gap-3">
                  <div class="notification-icon">
                    <img
                      src="@/assets/icon/discount-shape.svg"
                      alt=""
                    >
                  </div>
                  <span class="notification-desc fw-400 fs-12 lh-14 color-info-500">
                    Anda mendapatkan potongan sebesar {{ diskon }}
                  </span>
                </div>
              </div>
            </div>

              <!-- <div class="col-md-6">
                  <div class="col py-2">
                    <label class="form-label text-gray-3 fm-nunito fst-normal fw-600 fs-14 lh-14">
                        Metode Pembayaran
                    </label>
                    <div class="d-flex">
                      <div class="w-50">
                        <div class="pe-2">
                          <div
                            @click="cart.status_bayar = 0"
                            class="btn btn-gender fm-nunito fst-normal fw-600 fs-12 lh-16"
                            :class="{ 'active':cart.status_bayar === 0 }"
                          >
                            Pembayaran Online
                          </div>
                        </div>
                      </div>
                      <div class="w-50">
                        <div class="ps-2 d-flex justify-content-end">
                          <div
                            @click="cart.status_bayar = 1"
                            class="btn btn-gender fm-nunito fst-normal fw-600 fs-12 lh-16"
                            :class="{ 'active':cart.status_bayar === 1 }"
                          >
                            Pembayaran Offline
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col py-2">
                    <label class="form-label text-gray-3 fm-nunito fst-normal fw-600 fs-14 lh-14">
                        Hasil Cetak
                    </label>
                    <div class="d-flex">
                      <div class="w-50">
                        <div class="pe-2">
                          <div
                            @click="handlePrintOut(0)"
                            class="btn btn-gender fm-nunito fst-normal fw-600 fs-12 lh-16"
                            :class="{ 'active':cart.print_out === 0 }"
                          >
                            Tanpa Dicetak
                          </div>
                        </div>
                      </div>
                      <div class="w-50">
                        <div class="ps-2 d-flex justify-content-end">
                          <div
                            @click="handlePrintOut(1)"
                            class="btn btn-gender fm-nunito fst-normal fw-600 fs-12 lh-16"
                            :class="{ 'active':cart.print_out === 1 }"
                          >
                            Dicetak
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="cart.print_out === 1" class="col py-2">
                    <label class="form-label text-gray-3 fm-nunito fst-normal fw-600 fs-14 lh-14">
                        Metode Pengiriman
                    </label>
                    <div class="d-flex">
                      <div class="w-50">
                        <div class="pe-2">
                          <div
                            @click="handlePengiriman(0)"
                            class="btn btn-gender fm-nunito fst-normal fw-600 fs-12 lh-16"
                            :class="{ 'active':cart.dikirim_status === 0 }"
                          >
                            Ambil Sendiri
                          </div>
                        </div>
                      </div>
                      <div class="w-50">
                        <div class="ps-2 d-flex justify-content-end">
                          <div
                            @click="handlePengiriman(1)"
                            class="btn btn-gender fm-nunito fst-normal fw-600 fs-12 lh-16"
                            :class="{ 'active':cart.dikirim_status === 1 }"
                          >
                            Dikirim
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="cart.print_out === 1 && cart.dikirim_status === 1" class="col py-2">
                    <label class="form-label text-gray-3 fm-nunito fst-normal fw-600 fs-14 lh-14">
                        Alamat Pengiriman
                    </label>
                    <textarea
                        v-model="cart.dikirim_alamat"
                        name="alamat"
                        class="form-control form-input text-placeholder fm-nunito fst-normal fw-normal fs-12 bg-form-input pt-3 pb-3 ps-3 pe-2 border-white"
                        placeholder="Alamat pengiriman hasil cetak"
                      ></textarea>
                  </div>
              </div> -->
          </div>
        </div>

        <div class="col-md-4">
          <div :class="isSummaryForMobileShown ? 'backdrop' : ''" @click="closeSummary"/>
          <div class="booking-summary">
            <div class="card card-summary-order border-0">
              <div
                class="card-body p-0 d-flex flex-md-column"
                :class="{
                  'flex-row justify-content-between': !isSummaryForMobileShown,
                  'flex-column': isSummaryForMobileShown
                }">
                <div class="row d-md-none">
                  <div class="col d-flex justify-content-center pb-3">
                    <div class="tab bg-neutral-300" />
                  </div>
                </div>
                <div
                  class="summary-list py-md-2"
                  :class="{
                    'd-md-inline d-none': !isSummaryForMobileShown
                  }">
                  <div class="summary-group d-flex flex-column overflow-scroll">
                    <div class="summary-group-location">
                      <div class="mb-2">Lokasi Klinik</div>
                      <router-link
                        v-if="selectedBranch"
                        :to="{
                          name: 'SelectBranch',
                          query: {
                            redirectTo: $route.path
                          }
                        }"
                        class="address-box bg-neutral-100 d-md-none mb-3 d-flex align-items-center gap-2">
                        <div
                          class="icon-wrapper bg-neutral-300 d-flex align-items-center justify-content-center rounded-circle"
                        >
                          <img
                            src="@/assets/icon/location.png"
                            alt=""
                          />
                        </div>
                        <div class="address-box-info d-flex flex-column">
                          <strong class="address-box-info-name fw-700 color-neutral-900">{{ selectedBranch.nama }}</strong>
                          <small class="address-box-info-address fw-400 color-neutral-600">{{ selectedBranch.alamat }}</small>
                        </div>
                      </router-link>
                    </div>
                    <div class="summary-group-summary d-flex align-items-center justify-content-between mb-2">
                      <span class="">Pesanan</span>
                      <button
                        class="btn btn-add bg-secondary-100 rounded-pill py-0"
                        :to="{ name: 'Home' }"
                        @click="$router.push({ name: 'Home', query: { showMainServiceModal: true } })">
                        <span class="btn-title fw-700 fs-12 lh-12 color-secondary-500 py-1">Tambah Item</span>
                      </button>
                    </div>
                    <div
                      v-for="(summary) in Object.keys(summaries)" :key="summary"
                      class="summary-group-list">
                      <div
                        v-for="order in summaries[summary]"
                        :key="order"
                        class="service d-flex justify-content-between align-items-center">
                        <div class="service-group d-flex align-items-center gap-2">
                          <div class="service-icon">
                            <img
                              src="@/assets/dummy/swab.png"
                              alt=""
                            />
                          </div>
                          <div class="service-info">
                            <span class="service-title fw-700 color-neutral-900">{{ order.nama }}</span>
                            <p class="service-price fs-400 color-secondary-500 mb-0">Rp. {{ parseFloat(order.harga).toLocaleString('id-ID') }}</p>
                          </div>
                        </div>

                        <button
                          class="btn"
                          @click="removeFromCart(order)">
                          <img
                            src="@/assets/icon/trash.png"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>

                    <!-- Diskon -->
                    <div class="group d-flex flex-column">
                      <div class="bd-highlight py-2">
                        <p v-if="diskonPenggunaBaru.nominal > 0 || diskon" class="fst-normal text-gray-1 fw-600 fs-14 mb-0 fm-poppins">
                            Diskon
                        </p>

                        <div v-if="diskonPenggunaBaru.nominal > 0" class="d-flex flex-row align-items-center justify-content-between py-2">
                            <div class="bd-highlight">
                                <p class="text-gray-3 fs-14 fm-nunito lh-19 mb-0">Diskon Pengguna Baru</p>
                            </div>
                            <div class="bd-highlight">
                                <p class="text-gray-3 fs-14 fm-nunito mb-0 text-decoration-line-through">
                                  Rp. {{ diskonPenggunaBaru.nominal.toLocaleString('id-ID') }}
                                </p>
                            </div>
                        </div>
                        <div v-if="diskon" class="d-flex flex-row align-items-center justify-content-between py-2">
                            <div class="bd-highlight">
                                <p class="text-gray-3 fs-14 fm-nunito lh-19 mb-0">Voucher "{{ cart.kode_voucher }}"</p>
                            </div>
                            <div class="bd-highlight">
                                <p class="text-gray-3 fs-14 fm-nunito mb-0 text-decoration-line-through">
                                  {{ diskon }}
                                </p>
                            </div>
                        </div>
                        <div class="d-flex flex-row align-items-center justify-content-between py-2">
                            <div class="bd-highlight">
                                <p class="text-gray-3 fs-14 fm-nunito lh-19 mb-0">Sub Total</p>
                            </div>
                            <div class="bd-highlight">
                                <p class="text-gray-1 fs-14 fw-600 fm-nunito mb-0" :key="subTotal">
                                  Rp. {{ (subTotal - biayaOngkir).toLocaleString('id-ID') }}
                                </p>
                            </div>
                        </div>

                        <div class="divider">
                            <hr class="border bg-gray-6 my-0">
                        </div>

                        <p v-if="cart.dikirim_status === 1" class="fst-normal text-gray-1 fw-600 fs-14 mb-0 fm-poppins mt-2">
                            Biaya Tambahan
                        </p>

                        <div v-if="cart.dikirim_status === 1" class="d-flex flex-row align-items-center justify-content-between py-2">
                            <div class="bd-highlight">
                                <p class="text-gray-3 fs-14 fm-nunito lh-19 mb-0">Ongkos Kirim</p>
                            </div>
                            <div class="bd-highlight">
                                <p class="text-gray-3 fs-14 fm-nunito mb-0">
                                  Rp. 15.000
                                </p>
                            </div>
                        </div>

                        <div v-if="cart.dikirim_status === 1" class="divider">
                            <hr class="border bg-gray-6 my-0">
                        </div>

                        <div class="d-flex flex-row align-items-center justify-content-between py-2">
                            <div class="bd-highlight">
                                <p class="text-gray-3 fs-14 fm-nunito lh-19 mb-0">Total Tagihan</p>
                            </div>
                            <div class="bd-highlight">
                                <p class="text-gray-1 fs-14 fw-600 fm-nunito mb-0">
                                  Rp. {{ total.toLocaleString('id-ID') }}
                                </p>
                            </div>
                        </div>
                      </div>

                      <router-view />

                      <!-- <div class="bottom-bar p-3 p-md-0 text-center">
                        <span class="text-error fm-nunito fs-16 fw-600">{{ errorMessage }}</span>

                        <div class="voucher-group mb-2 d-block">
                          <i class="ri-coupon-line text-secondary"></i>
                          <input
                            v-model="cart.kode_voucher"
                            type="text"
                            class="form-control"
                            placeholder="Masukkan voucher"
                            @change="countVoucher"
                          >
                          <button
                            class="btn btn-sm btn-danger text-w me-5 me-md-0"
                            @click="clearVoucher">
                            <i class="text-white ri-delete-bin-6-line"></i>
                          </button>
                          <router-link
                            :to="{ name: 'PatientScanVoucher' }"
                            class="btn btn-sm btn-primary text-w d-md-none pt-1">
                            <i class="ri-qr-code-line"></i>
                          </router-link>
                        </div>

                        <div class="d-flex mb-md-3">
                          <button
                            class="btn bg-secondary me-1 fw-600 fs-14 w-100 py-2 text-white
                              d-flex justify-content-center align-items-center gap-1"
                            @click="$router.push({ name: 'Home' })"
                            :disabled="loading.order || cartLoading.voucher"
                          >
                            <i class="ri-add-line"></i>
                            Tambah Layanan
                          </button>
                          <button
                            class="btn bg-primary ms-1 fw-600 fs-14 w-100 py-2 text-white
                              d-flex justify-content-center align-items-center gap-1"
                            @click="openConfirmation"
                            :disabled="loading.order || cartLoading.voucher"
                          >
                            {{ (loading.order) ? 'Tunggu..' : 'Proses' }}
                            <i class="ri-arrow-right-line"></i>
                          </button>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>

                <!-- Voucher -->
                <div class="action w-100">
                  <router-link
                    v-if="!cart.kode_voucher"
                    class="voucher bg-neutral-100 d-flex align-items-center justify-content-between mx-3 my-3"
                    :to="cart.arr_paket.length !== 0 ? { name: 'UseVoucher', query: {from: 'checkout'} } : ''"
                  >
                    <div class="d-flex align-items-center gap-2">
                      <div class="voucher-icon d-flex align-items-center justify-content-center">
                        <img
                          src="@/assets/icon/voucher.png"
                          alt=""
                        >
                      </div>
                      <span class="voucher-label fw-400 color-neutral-900">Voucher Parahita</span>
                    </div>
                    <div class="d-flex align-items-center gap-2">
                      <span class="voucher-use fw-400 color-secondary-500">Gunakan/masukkan kode</span>
                      <div class="voucher-icon d-flex align-items-center justify-content-center">
                        <img
                          src="@/assets/icon/arrow-right-outline.png"
                          alt=""
                        >
                      </div>
                    </div>
                  </router-link>
                  <div v-else class="voucher bg-neutral-100 d-flex align-items-center justify-content-between mx-3 my-3">
                    <div class="d-flex align-items-center gap-2">
                      <div class="voucher-icon d-flex align-items-center justify-content-center">
                        <img
                          src="@/assets/icon/voucher.png"
                          alt=""
                        >
                      </div>
                      <span class="voucher-label fw-400 color-neutral-900">Voucher: "{{ cart.kode_voucher }}"</span>
                    </div>
                    <div class="d-flex align-items-center gap-2">
                      <button
                        class="btn p-0"
                        @click="clearVoucher">
                        <i class="text-danger ri-delete-bin-6-line"></i>
                      </button>
                    </div>
                  </div>
                  <div class="pay d-flex align-items-center justify-content-between pt-0 pb-3 px-3">
                    <router-link
                      class="pay-wrapper w-100"
                      :to="{
                        query: { showSummary: !isSummaryForMobileShown }
                      }">
                      <div class="pay-info d-flex flex-column">
                        <span class="pay-label fw-400 fs-12 lh-14 color-neutral-600 mb-1">Total Pembayaran</span>
                        <span class="pay-total fw-700 fs-14 lh-24 color-neutral-900">Rp. {{ total.toLocaleString('id-ID') }}</span>
                      </div>
                    </router-link>
                    <router-link
                      v-if="!isSummaryForMobileShown && isMobile"
                      class="btn btn-orange btn-pay fw-600 fs-14 w-100 py-2 text-white d-flex justify-content-center align-items-center gap-1"
                      :to="{
                        query: { showSummary: !isSummaryForMobileShown }
                      }">
                      Lanjutkan
                    </router-link>
                    <button
                      v-else
                      class="btn btn-orange btn-pay fw-600 fs-14 w-100 py-2 text-white d-flex justify-content-center align-items-center gap-1"
                      @click="openConfirmation">
                      Bayar Pesanan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div :class="isPatientForMobileShown ? 'backdrop' : ''" @click="closePatient"/>
        <div
          :class="!isPatientForMobileShown ? 'd-none' : 'col-md-4'">
          <div class="card card-patient border-0">
              <div
                class="card-body p-0 d-flex flex-column overflow-y-auto"
                :class="{
                  'flex-row justify-content-between': !isPatientForMobileShown,
                  'flex-column': isPatientForMobileShown
                }">
                <div class="row d-md-none">
                  <div class="col d-flex justify-content-center pb-3">
                    <div class="tab bg-neutral-300" />
                  </div>
                </div>
                <div
                  class="patient-list py-md-2 p-0 pb-3"
                  :class="{
                    'd-md-inline d-none': !isPatientForMobileShown
                  }">
                  <div
                    v-for="anggotaKeluarga in listAnggotaKeluarga"
                    :key="anggotaKeluarga"
                    @click="setSelectMember(anggotaKeluarga)"
                    class="patient-item d-flex align-items-center"
                  >
                    <div class="patient-wrapper d-flex align-items-center justify-content-center bg-info-500 rounded-circle">
                      <span class="patient-value fw-400 fs-14 lh-18 text-white">
                        {{ getInitialName(anggotaKeluarga.anggota_nama) }}
                      </span>
                    </div>
                    <div class="value-wrapper d-flex flex-column">
                      <span class="value-wrapper-name fw-700 fs-14 lh-18 color-neutral-900">{{ anggotaKeluarga.anggota_nama }}</span>
                      <label class="value-wrapper-email fw-400 fs-12 lh-14 color-neutral-600">{{ anggotaKeluarga.nama_hubungan }} ({{ anggotaKeluarga.anggota_nowa }})</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>

      <!-- divider -->
      <div class="d-md-none my-3 py-3"></div>
    </div>

    <AddPatient/>
    <Anamnesa v-model:statusAnamnesa="statusAnamnesa"/>
  </div>

  <!-- Modal -->
  <div class="modal fade"
    id="modal-confirmation"
    tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body text-center d-flex flex-column py-3">
            <h5 class="modal-title" id="exampleModalLabel">Konfirmasi</h5>
            <p v-for="confirmationMessage in confirmationMessages" :key="confirmationMessage.val">
              {{ confirmationMessage.val }}
            </p>
            <div class="group-button pb-2 pt-3">
              <button
                type="button"
                class="btn btn-secondary text-white w-25 me-1" data-bs-dismiss="modal">Tidak</button>
              <button
                @click="submit"
                type="button" class="btn text-white btn-danger w-25 ms-1">
                Ya
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
</template>

<script>
import { nextTick, onMounted, ref, computed, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import AddPatient from '@/components/home/service/covid/AddPatient'
import Anamnesa from '@/components/home/service/covid/Anamnesa'
import { selectedMember, submitOrder, loading, getConfirmation } from '@/core/service'
import {
  cart, diskon, getCart, clearCart,
  clearVoucher, countVoucher, loading as cartLoading,
  removeFromCart, diskonPenggunaBaru, voucherTemp
} from '@/core/cart'
import { user, kode_promo } from '@/core/auth'
import AppBar from '@/components/AppBar.vue'
import { showNotif } from '@/core/utility'
import { Modal } from 'bootstrap'
import { title } from '@/core/page'
import { Field, ErrorMessage } from 'vee-validate'
import { selectedBranch } from '@/core/subDivisions'
import { getAnggotaKeluarga, listAnggotaKeluarga } from '@/core/anggotaKeluarga'

let modal = null

export default {
  components: {
    AddPatient,
    AppBar,
    Anamnesa,
    Field,
    ErrorMessage
  },
  setup () {
    // Ambil list anggota
    onMounted(() => {
      getAnggotaKeluarga()
    })

    // Prepare variable
    const errorMessage = ref('')
    const router = useRouter()
    const route = useRoute()
    const statusAnamnesa = ref(false)
    const confirmationMessages = ref([])
    const biayaOngkir = ref(0)
    const useMyData = ref(true)
    const isMobile = computed(() => window.screen.width <= 767)

    // Prepare computed
    const subTotal = computed(() => {
      return parseInt(cart.value.ap_nombayar) - parseInt(cart.value.nominal_voucher)
    })

    const total = computed(() => {
      return parseInt(subTotal.value)
    })

    const summaries = computed(() => {
      const temp = []
      cart.value.arr_paket.map((data) => {
        const nama_group = data.nama_group ?? data.nama_group_fpp
        if (!temp[nama_group]) {
          temp[nama_group] = [data]
        } else {
          temp[nama_group].push(data)
        }
      })
      return temp
    })

    const isSummaryForMobileShown = computed(() => route.query.showSummary === 'true')
    const isPatientForMobileShown = computed(() => route.query.showPatient === 'true')

    const date = reactive({
      tgl: '',
      bln: '',
      thn: ''
    })

    const getInitialName = (fullname) => {
      if (fullname) {
        const totalWord = fullname.split(' ').length
        const fullName = fullname.split('')

        // cek jumlah kata
        if (totalWord === 1) {
          const initials = fullName.shift().charAt(0)
          return initials.toUpperCase()
        }

        const initials = fullname.split(' ')[0][0] + fullname.split(' ')[1][0]
        return initials.toUpperCase()
      }

      return ''
    }

    onMounted(async () => {
      await getCart()
      selectedMember.nama = user.value.pas_nama
      selectedMember.pas_id_periksa = user.value.pas_id
      selectedMember.email = user.value.email_user
      selectedMember.nowa = user.value.pas_wa
      selectedMember.nama_hubungan = 'SAYA'

      // kalau sudah pakai voucher langsung hitung yaaaa
      cart.value.kode_voucher = kode_promo.value.kode_voucher

      if (voucherTemp.value) {
        cart.value.kode_voucher = voucherTemp.value
      }

      nextTick(() => {
        modal = new Modal(
          document.querySelector('#modalAddPatient'), {
            backdrop: 'static'
          }
        )

        if (route.query.modalPatient === 'show') {
          modal.show()
        }

        title.value = 'Checkout'
      })
      countVoucher() // Recheck + recount voucher
    })

    const submit = async () => {
      try {
        // Anamnesa wajib hukumnya
        // if (!cart.value.anamnesa_keluahan ||
        //     !cart.value.anamnesa_jam_makan_terakhir ||
        //     !cart.value.anamnesa_obat_yg_dikonsumsi ||
        //     !cart.value.anamnesa_riwayat_penyakit ||
        //     !cart.value.anamnesa_riwayat_alergi ||
        //     !cart.value.anamnesa_penyakit_keluarga
        // ) {
        //   errorMessage.value = 'Anamnesa haru diisi semua.'
        //   // Trigger toast
        //   showNotif({
        //     status: 'error',
        //     message: errorMessage.value
        //   })
        //   return
        // }

        modal.hide()
        const result = await submitOrder()
        router.push({
          name: 'Summary',
          params: {
            id_order: result.data.kode_daftar
          }
        })
      } catch (error) {
        errorMessage.value = error.response.data.message
      }
    }

    const handlePrintOut = (value) => {
      cart.value.print_out = value

      // Reset data lainnya jika tidak jadi cetak
      if (value === 0) {
        // Reset ongkit
        if (cart.value.dikirim_status !== 0) {
          cart.value.ap_nombayar -= 15000
          biayaOngkir.value = 0
        }
        // Reset data selanjutnya
        cart.value.dikirim_status = 0
        cart.value.dikirim_alamat = ''
      }
    }

    const handlePengiriman = (value) => {
      // Reset data lainnya jika tidak jadi cetak
      if (cart.value.dikirim_status !== value) {
        if (value === 0) {
          cart.value.dikirim_alamat = ''

          // Reset ongkit
          cart.value.ap_nombayar -= 15000
          biayaOngkir.value = 0
        } else { // Tambahkan 15.000 sbg ongkos kirim flat
          cart.value.ap_nombayar += 15000
          biayaOngkir.value = 15000
        }
      }
      cart.value.dikirim_status = value
    }

    const closeSummary = () => {
      if (isSummaryForMobileShown.value) {
        router.push({
          query: { showSummary: !isSummaryForMobileShown.value }
        })
      }
    }

    const closePatient = () => {
      if (isPatientForMobileShown.value) {
        router.push({
          query: { showSummary: !isPatientForMobileShown.value }
        })
      }
    }

    const openConfirmation = async () => {
      cart.value.tgl_periksa = date.thn + '-' + date.bln + '-' + date.tgl
      errorMessage.value = ''
      // Tangal periksa itu wajib ya
      if (!cart.value.tgl_periksa || isNaN(new Date(cart.value.tgl_periksa))) {
        errorMessage.value = 'Silahkan pilih tanggal periksa'
      }

      // Tanggal periksa harus >= hari ini
      if (new Date(cart.value.tgl_periksa).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
        errorMessage.value = 'Tanggal periksa harus lebih dari atau sama dengan hari ini'
      }

      // jika bayar online dan total bayar cuma 0
      // maka jangan, nanti error midtrans soalnya
      if (cart.value.status_bayar === 0 && total.value < 1) {
        errorMessage.value = 'Minimal pembelian Rp.1'
      }

      // Alamat wajib jika dikirim
      if (cart.value.print_out === 1 && cart.value.dikirim_status === 1 && !cart.value.dikirim_alamat) {
        errorMessage.value = 'Alamat harus diisi jika ingin dikirim.'
      }

      if (confirmationMessages.value.length < 1) {
        const confirmation = await getConfirmation()
        if (confirmation.status) {
          confirmationMessages.value = confirmation.message
        } else {
          errorMessage.value = confirmation.message
        }
      }

      if (errorMessage.value) {
        closeSummary()
        // Trigger toast
        showNotif({
          status: 'error',
          message: errorMessage.value
        })
        return
      }

      modal = new Modal(
        document.querySelector('#modal-confirmation'), {
          backdrop: 'static'
        }
      )
      modal.show()
    }

    const setMyData = () => {
      useMyData.value = true

      selectedMember.nama = user.value.pas_nama
      selectedMember.pas_id_periksa = user.value.pas_id
      selectedMember.email = user.value.email_user
      selectedMember.nowa = user.value.pas_wa
      selectedMember.nama_hubungan = 'SAYA'

      // set status periksa diri sendiri
      cart.value.status_periksa = 1
    }

    const setSelectMember = payload => {
      useMyData.value = false

      selectedMember.nama = payload.anggota_nama
      selectedMember.pas_id_periksa = payload.pas_id
      selectedMember.email = payload.anggota_email_user
      selectedMember.nowa = payload.anggota_nowa
      selectedMember.nama_hubungan = payload.nama_hubungan

      // set status periksa orang lain
      cart.value.status_periksa = 2
      closePatient()
    }

    return {
      cart,
      user,
      submitOrder,
      selectedMember,
      loading,
      errorMessage,
      submit,
      total,
      diskon,
      clearCart,
      statusAnamnesa,
      handlePrintOut,
      handlePengiriman,
      summaries,
      removeFromCart,
      clearVoucher,
      countVoucher,
      cartLoading,
      openConfirmation,
      confirmationMessages,
      diskonPenggunaBaru,
      subTotal,
      biayaOngkir,
      date,
      useMyData,
      getInitialName,
      isSummaryForMobileShown,
      selectedBranch,
      closeSummary,
      isPatientForMobileShown,
      closePatient,
      voucherTemp,
      isMobile,
      setSelectMember,
      setMyData,
      listAnggotaKeluarga
    }
  },
  watch: {
    '$route.query': function (query) {
      if (query.voucher) {
        cart.value.kode_voucher = query.voucher
      }

      if (modal) {
        if (query.modalPatient === 'show') {
          modal.show()
          console.log('show')
        } else {
          modal.hide()
          console.log('hide modal')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-control, .input-group-text {
  background: none;
  border-radius: 0;
  box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
  padding: 8px 4px 8px 0px;
  font-size: 18px;
  border: none;
}

.form-control {
  &.input-note {
    border-radius: 12px;
    padding: 8px 14px;
  }
}

.form-control::placeholder {
  color: #CBD5E0;
}

.form-control.is-invalid, .input-group-text.is-invalid {
  box-shadow: inset 0px -1px 0px #E21F39;
}

.input-group>.form-control.form-error {
  background: #FFDDEB;
  color: #14142B;
}

.initial {
  padding: 9px 8px;
  border-radius: 12px;
  gap: 8px;

  &-wrapper {
    width: 36px;
    height: 36px;
  }

  .value-wrapper {
    gap: 4px;
  }
}

select.select-patient,
select.select-month {
  height: 100%;
  font-size: 18px;
  line-height: 24px;
  border: none;
  box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;
  background-image: url("../../../assets/icon/select-arrow.png");
  background-repeat: no-repeat;
  background-size: 14px auto;
}

.payment-method {
  width: 24px;
  height: 24px;

  &-label:active {
    color: #4A5568;
  }
}

.payment-method:checked {
  accent-color: #007A98;
}

.notification {
  padding: 10px;
  border-radius: 12px;

  &-desc {
    @media (max-width: 767px) {
      max-width: 281px;
    }
  }
}

.card-patient {
  border-radius: 10px !important;
  box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.05);
  padding-top: 8px;
  z-index: 100;

  @media (max-width: 767px) {
    position: fixed;
    bottom: 0rem;
    left: 0;
    right: 0;
    transition: all .3s ease-in-out;
    max-height: 80vh;
  }

  @media (min-width: 768px) {
    position: fixed;
    top: 2rem;
    left: 25rem;
    right: 25rem;
    transition: all .3s ease-in-out;
    max-height: 93vh;
  }

  .tab {
    width: 48px;
    height: 4px;
    border-radius: 14px;
  }

  .patient-list {
    padding: 8px 18px 25x;
    border-radius: 20px 20px 0px 0px;

    @media (max-width: 767px) {
      height: 65vh;
      overflow-y: auto;
    }

    .patient-item {
      padding: 12px 18px;
      gap: 8px;
      box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;

      .value-wrapper {
        gap: 4px;
      }

      .btn-wrapper {
        bottom: 0;
      }

      &.active {
        color: white;
        background-color: #8CC63F;
      }
    }

    .patient-wrapper {
      width: 36px;
      height: 36px;
    }
  }
}

.booking-summary {
  @media (max-width: 767px) {
    position: fixed;
    bottom: 0rem;
    left: 0;
    right: 0;
    transition: all .3s ease-in-out;
    max-height: 93vh;
    z-index: 100;
  }

  .card-summary-order {
    border-radius: 10px !important;
    box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.05);
    padding-top: 8px;

    .tab {
      width: 48px;
      height: 4px;
      border-radius: 14px;
    }

    .summary {
      &-list {
        padding: 8px 18px 20px;
        border-radius: 20px 20px 0px 0px;
        overflow-y: auto;

        @media (max-width: 767px) {
          max-height: 80vh;
        }
      }

      &-group {
        @media (max-width: 767px) {
          height: 60vh;
          margin-bottom: 6rem;
        }

        &-location {
          .address-box {
            position: relative;
            padding: 13px 8px;
            border-radius: 12px;
            padding: 8px;

            .icon-wrapper {
              width: 34px;
              height: 34px;

              img {
                width: 18px;
                height: auto;
              }
            }

            &-info {
              &-name {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 4px;
              }

              &-address {
                font-size: 12px;
                line-height: 14px;
              }
            }
          }
        }

        &-list {
          .service {
            box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;

            &-icon {
              width: 42px;
              height: 42px;
            }

            &-group {
              padding: 12px 0;
            }

            &-title {
              font-size: 14px;
              line-height: 24px;
              margin-bottom: 2px;
            }

            &-price {
              font-size: 12px;
              line-height: 14px;
            }
          }

          .service:last-child {
            box-shadow: unset;
          }
        }

        .recomended-list {
          gap: 4px;

          .recomended-item {
            box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;
            padding: 8px 0;

            &-name {
              font-size: 14px;
              line-height: 24px;
              margin-bottom: 4px;
            }

            &-price {
              font-size: 12px;
              line-height: 14px;
            }
          }

          .recomended-item:last-child {
            box-shadow: unset;
          }
        }
      }
    }

    .action {
      @media (max-width: 767px) {
        background-color: white;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        box-shadow: 0px 1px 0px 0px #F2F2F2CC inset;
      }

      .voucher {
        padding: 11px;
        border-radius: 12px;

        &-icon {
          width: 20px;
          height: 20px;
        }

        &-label {
          font-size: 12px;
          line-height: 22px;
        }

        &-use {
          font-size: 10px;
          line-height: 22px;
        }
      }

      .pay {
        .btn-pay {
          width: 169px !important;

          @media (max-width: 767px) {
            position: absolute;
            right: 20px;
            z-index: 99 !important;
          }
        }

        .btn-pay:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

// .voucher-group {
//   position: relative;

//   i.ri-coupon-line {
//     position: absolute;
//     left: .5rem;
//     top: 50%;
//     z-index: 39;
//     transform: translateY(-50%);
//   }

//   input {
//     padding: .7rem 2rem;
//   }

//   .btn {
//     position: absolute;
//     right: .5rem;
//     top: 50%;
//     transform: translateY(-50%);
//     padding: .2rem .5rem;
//   }
// }
</style>
