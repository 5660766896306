<template>
  <div class="main-page">
    <div class="container px-0">
      <app-bar
        title="Edit Keluarga"
        :previousLink="{ name: 'SettingAnggotaKeluarga' }"
      />
      <div class="col-12">
        <div class="form-patient">
          <div class="group-title mb-4">
            <span class="title-content-package">Edit Keluarga</span> <br>
            <span class="fw-normal fs-12 color-neutral-600">Silahkan lengkapi data diri di bawah ini</span>
          </div>

          <form @submit.prevent="submit">
            <div>
              <label>Hubungan Keluarga</label>
              <div class="mb-3">
                <select
                  class="form-select select-month color-neutral-900"
                  :class="{ 'is-invalid': errors.hubungan }"
                  id="selectHubunganKeluarga"
                  v-model="dataRegister.hubungan"
                  name="hubungan"
                >
                  <option selected disabled value="null">Pilih Hubungan Keluarga</option>
                  <option v-for="hubunganKeluarga in listHubunganKeluarga" :key="hubunganKeluarga.id_hubungan" :value="hubunganKeluarga.id_hubungan">{{ hubunganKeluarga.nama_hubungan }}</option>
                </select>
                <span v-if="errors.hubungan" class="invalid-feedback">{{ errors.hubungan }}</span>
              </div>
            </div>

            <div>
              <label>Nama Lengkap</label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  v-model="dataRegister.nama"
                  name="nama"
                  class="form-control"
                  :class="{ 'is-invalid': errors.nama }"
                  placeholder="Cth: Para Hita Lestari" />
                <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.nama }">
                  <div class="show-password" type="button" @click="dataRegister.nama = ''" :class="{'opacity-0':!dataRegister.nama}">
                    <i class="ri-close-circle-fill fs-24"></i>
                  </div>
                </span>
                <span v-if="errors.nama" class="invalid-feedback">{{ errors.nama }}</span>
              </div>
            </div>

            <div>
              <label>Nomor KTP</label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  v-model="dataRegister.noktp"
                  name="noktp"
                  class="form-control"
                  :class="{ 'is-invalid': errors.noktp }"
                  placeholder="357xxxxxxxxxxxxx" />
                <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.noktp }">
                  <div class="show-password" type="button" @click="dataRegister.noktp = ''" :class="{'opacity-0':!dataRegister.noktp}">
                    <i class="ri-close-circle-fill fs-24"></i>
                  </div>
                </span>
                <span v-if="errors.noktp" class="invalid-feedback">{{ errors.noktp }}</span>
              </div>
            </div>

            <div>
              <label>Tanggal Lahir</label>
              <div class="input-group mb-3 row-flex justify-content-between gap-1">
                <div class="col-3">
                  <input
                    type="number"
                    v-model="date.tgl"
                    name="tgllahir"
                    class="form-control"
                    :class="{ 'is-invalid': errors.tgllahir }"
                    min="1"
                    placeholder="Tanggal"
                  />
                </div>
                <div class="col-5">
                  <select
                    class="form-select select-month color-neutral-900"
                    :class="{ 'is-invalid': errors.tgllahir }"
                    id="inputGroupSelect01"
                    v-model="date.bln"
                    name="bulan"
                  >
                      <option selected disabled value="">Bulan</option>
                      <option value="01">Januari</option>
                      <option value="02">Februari</option>
                      <option value="03">Maret</option>
                      <option value="04">April</option>
                      <option value="05">Mei</option>
                      <option value="06">Juni</option>
                      <option value="07">Juli</option>
                      <option value="08">Agustus</option>
                      <option value="09">September</option>
                      <option value="10">Oktober</option>
                      <option value="11">November</option>
                      <option value="12">Desember</option>
                  </select>
                </div>
                <div class="col-3">
                  <input
                    type="number"
                    v-model="date.thn"
                    name="tahun"
                    min="1900"
                    class="form-control"
                    :class="{ 'is-invalid': errors.tgllahir }"
                    placeholder="Tahun"
                  />
                </div>
                <span v-if="errors.tgllahir" class="invalid-feedback">{{ errors.tgllahir }}</span>
              </div>
            </div>

            <!-- Provinsi -->
            <section class="mb-3">
              <label>Provinsi</label>
              <select
                v-model="dataRegister.kode_provinsi"
                name="kode_provinsi"
                id="kode_provinsi"
                rules="required"
                class="form-select select-month color-neutral-900"
                :class="{ 'is-invalid': errors.kode_provinsi }"
                @change="onChangeProvince($event)"
              >
                <option selected disabled value="null">Pilih Provinsi</option>
                <option
                  v-for="province in provinces"
                  :key="province"
                  :value="province.kode"
                >
                  {{ capitalize(province.nama) }}
                </option>
              </select>
              <span v-if="errors.kode_provinsi" class="invalid-feedback">{{ errors.kode_provinsi }}</span>
            </section>

            <!-- Kota -->
            <section class="mb-3">
              <label>Kota / Kabupaten</label>
              <select
                v-model="dataRegister.kode_kota"
                name="kode_kota"
                id="kode_kota"
                rules="required"
                class="form-select select-month color-neutral-900"
                :class="{ 'is-invalid': errors.kode_kota }"
                @change="onChangeCity($event)"
              >
                <option selected disabled value="null">Pilih Kota / Kabupaten</option>
                <option
                  v-for="city in cities"
                  :key="city"
                  :value="city.kode"
                >
                  {{ capitalize(city.nama) }}
                </option>
              </select>
              <span v-if="errors.kode_kota" class="invalid-feedback">{{ errors.kode_kota }}</span>
            </section>

            <!-- Kecamatan -->
            <section class="mb-3">
              <label>Kecamatan</label>
              <select
                v-model="dataRegister.kode_kecamatan"
                name="kode_kecamatan"
                id="kode_kecamatan"
                rules="required"
                class="form-select select-month color-neutral-900"
                :class="{ 'is-invalid': errors.kode_kecamatan }"
                @change="onChangeSubDistrict($event)"
              >
                <option selected disabled value="null">Pilih Kecamatan</option>
                <option
                  v-for="subDistrict in subDistricts"
                  :key="subDistrict"
                  :value="subDistrict.kode"
                >
                  {{ capitalize(subDistrict.nama) }}
                </option>
              </select>
              <span v-if="errors.kode_kecamatan" class="invalid-feedback">{{ errors.kode_kecamatan }}</span>
            </section>

            <!-- Kelurahan -->
            <section class="mb-3">
              <label>Kelurahan</label>
              <select
                v-model="dataRegister.kode_kelurahan"
                name="kode_kelurahan"
                id="kode_kelurahan"
                rules="required"
                class="form-select select-month color-neutral-900"
                :class="{ 'is-invalid': errors.kode_kelurahan }"
              >
                <option selected disabled value="null">Pilih Kelurahan</option>
                <option
                  v-for="ward in wards"
                  :key="ward"
                  :value="ward.kode"
                >
                  {{ capitalize(ward.nama) }}
                </option>
              </select>
              <span v-if="errors.kode_kelurahan" class="invalid-feedback">{{ errors.kode_kelurahan }}</span>
            </section>

            <div>
              <label class="mb-1">Alamat Lengkap</label>
              <div class="mb-3">
                <textarea
                  type="text"
                  v-model="dataRegister.alamat"
                  name="alamat"
                  class="form-control input-note border"
                  :class="{ 'is-invalid': errors.alamat }"
                  placeholder="Alamat lengkap..."
                ></textarea>
                <span v-if="errors.alamat" class="invalid-feedback">{{ errors.alamat }}</span>
              </div>
            </div>

            <div>
              <label class="mb-2">Jenis Kelamin</label>
              <div class="mb-3">
                <div class="d-flex justify-content-between">
                  <div class="bd-highlight w-50">
                    <div class="form-check pe-2">
                      <div
                        @click="dataRegister.jenkelamin = 'L'"
                        class="btn btn-gender d-flex justify-content-between align-items-center rounded-pill"
                        :class="{ active: (dataRegister.jenkelamin === 'L') }"
                      >
                        <p class="fst-normal fw-600 fs-12 lh-16 mb-0">Laki-laki</p>
                        <div
                          class="box-icon rounded-circle d-flex align-items-center justify-content-center"
                          :class="{active: (dataRegister.jenkelamin === 'L') }">
                            <i class="ri-men-line icon-gender"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bd-highlight w-50">
                    <div class="form-check ps-2 d-flex justify-content-end">
                      <div
                        @click="dataRegister.jenkelamin = 'P'"
                        class="btn btn-gender d-flex justify-content-between align-items-center rounded-pill"
                        :class="{ active: (dataRegister.jenkelamin === 'P') }"
                      >
                        <p class="fst-normal fw-600 fs-12 lh-16 mb-0">Perempuan</p>
                        <div
                          class="box-icon rounded-circle d-flex align-items-center justify-content-center"
                          :class="{active: (dataRegister.jenkelamin === 'P') }">
                            <i class="ri-women-line icon-gender"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <span v-if="errors.jenkelamin" class="invalid-feedback">{{ errors.jenkelamin }}</span>
              </div>
            </div>

            <div>
              <label>No. Telp</label>
              <div class="input-group mb-3">
                <VueTelInput
                  v-model="dataRegister.no_hp"
                  name="no_hp"
                  mode="international"
                  class="form-control"
                  :class="{ 'is-invalid': errors.no_hp }"
                  :inputOptions="{
                    'placeholder': vueTelOption.placeholder
                  }"
                  :validCharactersOnly="true"
                  @on-input="handleInputHP"
                  @change="handleWa"
                />
                <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.no_hp }">
                  <div class="show-password" type="button" @click="dataRegister.no_hp = ''" :class="{'opacity-0':!dataRegister.no_hp}">
                    <i class="ri-close-circle-fill fs-24"></i>
                  </div>
                </span>
                <span v-if="errors.no_hp" class="invalid-feedback">{{ errors.no_hp }}</span>
              </div>
            </div>

            <div v-if="!sameAsWa">
              <label>WhatsApp</label>
              <div class="input-group mb-3">
                <VueTelInput
                  v-model="dataRegister.nowa"
                  name="nowa"
                  mode="international"
                  class="form-control"
                  :class="{ 'is-invalid': errors.nowa }"
                  :inputOptions="{
                    'placeholder': vueTelOption.placeholder
                  }"
                  :validCharactersOnly="true"
                  @on-input="handleInputWA"
                  @change="handleWa"
                />
                <span class="input-group-text bg-grayscale border-0" :class="{ 'is-invalid': errors.nowa }">
                  <div class="show-password" type="button" @click="dataRegister.nowa = ''" :class="{'opacity-0':!dataRegister.nowa}">
                    <i class="ri-close-circle-fill fs-24"></i>
                  </div>
                </span>
                <span v-if="errors.nowa" class="invalid-feedback">{{ errors.nowa }}</span>
              </div>
            </div>

            <div class="input-group mb-2">
              <div class="form-check align-items-center">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  v-model="sameAsWa"
                  @change="handleWa"
                >
                <label class="form-label ms-2 mb-0 fw-400 fs-12 color-neutral-700" for="exampleCheck1">No. Telp sama dengan WhatsApp</label>
              </div>
            </div>

            <div class="text-center">
              <span v-if="errorMessageApi" class="invalid-feedback large mt-4">{{ errorMessageApi }}</span>
              <button
                type="submit"
                class="btn btn-primary w-100 py-2 p-md-2 mt-4 mb-3"
                :disabled="loadingAddPatient">
                {{ loadingAddPatient ? 'Loading..' : 'Simpan' }}
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import { ref, reactive, onMounted } from 'vue'
import { user } from '@/core/auth'
import { getHubunganKeluarga, listHubunganKeluarga, anggotaKeluargaDetail, getAnggotaKeluargaDetail, updateAnggotaKeluarga } from '@/core/anggotaKeluarga'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import { useRouter, useRoute } from 'vue-router'
import { provinces, cities, subDistricts, wards, getProvinces, getCities, getSubDistricts, getWards } from '@/core/region'
import { showNotif } from '@/core/utility'

export default {
  components: {
    AppBar,
    VueTelInput
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const sameAsWa = ref(false)
    const errors = reactive({
      nama: null,
      noktp: null,
      tgllahir: null,
      hubungan: null,
      no_hp: null,
      nowa: null,
      jenkelamin: null,
      alamat: null,
      kode_provinsi: null,
      kode_kota: null,
      kode_kecamatan: null,
      kode_kelurahan: null
    })
    const errorMessageApi = ref('')
    const loadingAddPatient = ref(false)
    const date = reactive({
      tgl: null,
      bln: '',
      thn: null
    })

    const dataRegister = ref({
      nama: null,
      noktp: null,
      tgllahir: null,
      hubungan: null,
      no_hp: null,
      nowa: null,
      jenkelamin: null,
      alamat: null,
      kode_provinsi: null,
      kode_kota: null,
      kode_kecamatan: null,
      kode_kelurahan: null,
      pasien_kode: user.value.pas_id,
      pasien_id: user.value.id
    })

    const capitalize = (word) => {
      return word.toLowerCase().replace(/\b./g, function (a) {
        return a.toUpperCase()
      })
    }

    const handleWa = () => {
      if (sameAsWa.value) {
        dataRegister.value.nowa = dataRegister.value.no_hp
      }
    }

    const handleInputHP = (phone, phoneObject) => {
      if (phoneObject?.formatted) {
        dataRegister.value.no_hp = phoneObject.formatted
      }
    }

    const handleInputWA = (phone, phoneObject) => {
      if (phoneObject?.formatted) {
        dataRegister.value.nowa = phoneObject.formatted
      }
    }

    const resetError = () => {
      errors.nama = null
      errors.noktp = null
      errors.tgllahir = null
      errors.hubungan = null
      errors.no_hp = null
      errors.nowa = null
      errors.jenkelamin = null
      errors.kota = null
      errors.alamat = null
      errors.kode_provinsi = null
      errors.kode_kota = null
      errors.kode_kecamatan = null
      errors.kode_kelurahan = null
    }

    const validateForm = () => {
      // reset pesan error
      resetError()

      let isWorthy = true

      if (!dataRegister.value.nama) {
        errors.nama = 'Nama wajib diisi!'
        isWorthy = false
      }

      if (!dataRegister.value.noktp) {
        errors.noktp = 'Nomor KTP wajib diisi!'
        isWorthy = false
      }

      if (!dataRegister.value.tgllahir) {
        errors.tgllahir = 'Tanggal lahir wajib diisi!'
        isWorthy = false
      }

      if (!dataRegister.value.hubungan) {
        errors.hubungan = 'Hubungan keluarga wajib diisi!'
        isWorthy = false
      }

      if (!dataRegister.value.no_hp) {
        errors.no_hp = 'Nomor telp wajib diisi!'
        isWorthy = false
      }

      if (!dataRegister.value.nowa) {
        errors.nowa = 'Nomor whatsapp wajib diisi!'
        isWorthy = false
      }

      if (!dataRegister.value.jenkelamin) {
        errors.jenkelamin = 'Jenis kelamin wajib diisi!'
        isWorthy = false
      }

      if (!dataRegister.value.alamat) {
        errors.alamat = 'Alamat lengkap wajib diisi!'
        isWorthy = false
      }

      if (!dataRegister.value.kode_provinsi) {
        errors.kode_provinsi = 'Provinsi wajib diisi!'
        isWorthy = false
      }

      if (!dataRegister.value.kode_kota) {
        errors.kode_kota = 'Kota wajib diisi!'
        isWorthy = false
      }

      if (!dataRegister.value.kode_kecamatan) {
        errors.kode_kecamatan = 'Kecamatan wajib diisi!'
        isWorthy = false
      }

      if (!dataRegister.value.kode_kelurahan) {
        errors.kode_kelurahan = 'Kelurahan wajib diisi!'
        isWorthy = false
      }

      return isWorthy
    }

    const onChangeProvince = async (event) => {
      const kode = event.target.value
      await getCities(kode)

      // reset form
      dataRegister.value.kode_kecamatan = ''
      dataRegister.value.kode_kelurahan = ''

      // reset list
      subDistricts.value = []
      wards.value = []
    }

    const onChangeCity = async (event) => {
      const kode = event.target.value
      await getSubDistricts(kode)

      // reset form
      dataRegister.value.kode_kelurahan = ''

      // reset list
      wards.value = []
    }

    const onChangeSubDistrict = async (event) => {
      const kode = event.target.value
      await getWards(kode)
    }

    const submit = async () => {
      try {
        // format tgl lahir
        dataRegister.value.tgllahir = date.thn + '-' + date.bln + '-' + date.tgl

        // validasi form dulu
        if (!validateForm()) {
          return
        }

        loadingAddPatient.value = true
        // Reset pesan errornya dulu
        errorMessageApi.value = ''

        // prepare form data
        const fd = new FormData()

        // Append object ke formData
        Object.keys(dataRegister.value).forEach(key => fd.append(key, dataRegister.value[key]))

        const result = await updateAnggotaKeluarga(fd)
        if (!result.status) {
          errorMessageApi.value = result.message
          return
        }

        showNotif({
          status: 'success',
          message: 'Berhasil edit anggota keluarga'
        })

        return router.push({ name: 'SettingAnggotaKeluarga' })
      } catch (error) {
        console.error('INI ERROR = ', error)
        errorMessageApi.value = error.response.data.message
      } finally {
        loadingAddPatient.value = false
      }
    }

    const prepareAnggotaKeluargaDetail = async () => {
      try {
        const result = await getAnggotaKeluargaDetail({
          id_user: user.value.id,
          pas_id: route.params.pas_id
        })

        if (result.status) {
          if (anggotaKeluargaDetail?.value?.tgllahir) {
            const unformattedDate = anggotaKeluargaDetail.value.tgllahir.split('-')

            date.tgl = unformattedDate[2]
            date.bln = unformattedDate[1]
            date.thn = unformattedDate[0]
          }

          dataRegister.value.nama = anggotaKeluargaDetail.value.nama
          dataRegister.value.noktp = anggotaKeluargaDetail.value.noktp
          dataRegister.value.tgllahir = anggotaKeluargaDetail.value.tgllahir
          dataRegister.value.hubungan = anggotaKeluargaDetail.value.hubungan
          dataRegister.value.no_hp = anggotaKeluargaDetail.value.no_hp
          dataRegister.value.nowa = anggotaKeluargaDetail.value.nowa
          dataRegister.value.jenkelamin = anggotaKeluargaDetail.value.jenkelamin
          dataRegister.value.alamat = anggotaKeluargaDetail.value.alamat
          dataRegister.value.kode_provinsi = anggotaKeluargaDetail.value.kode_provinsi
          dataRegister.value.kode_kota = anggotaKeluargaDetail.value.kode_kota
          dataRegister.value.kode_kecamatan = anggotaKeluargaDetail.value.kode_kecamatan
          dataRegister.value.kode_kelurahan = anggotaKeluargaDetail.value.kode_kelurahan
          dataRegister.value.pas_id = anggotaKeluargaDetail.value.pas_id
        }
      } catch (error) {
        console.error('ERROR GET ANGGOTA KELUARGA DETAIL = ', error)
        errorMessageApi.value = error.response.data.message
      }
    }

    onMounted(async () => {
      await getHubunganKeluarga()
      await getProvinces()
      await prepareAnggotaKeluargaDetail()

      if (dataRegister.value.pas_wa === dataRegister.value.pas_hp) {
        sameAsWa.value = true
      }

      if (dataRegister.value.kode_provinsi) {
        await getCities(dataRegister.value.kode_provinsi)
      }

      if (dataRegister.value.kode_kota) {
        await getSubDistricts(dataRegister.value.kode_kota)
      }

      if (dataRegister.value.kode_kecamatan) {
        await getWards(dataRegister.value.kode_kecamatan)
      }
    })

    return {
      dataRegister,
      date,
      errorMessageApi,
      loadingAddPatient,
      handleInputWA,
      submit,
      listHubunganKeluarga,
      errors,
      handleInputHP,
      sameAsWa,
      handleWa,
      onChangeProvince,
      onChangeCity,
      onChangeSubDistrict,
      provinces,
      cities,
      subDistricts,
      wards,
      capitalize
    }
  },
  data () {
    return {
      vueTelOption: {
        placeholder: '8xxx xxxx xxxx'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
select.select-patient {
  height: 100%;
  font-size: 18px;
  line-height: 24px;
  border: none;
  box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;
  background-image: url("../../../assets/icon/select-arrow.png");
  background-repeat: no-repeat;
  background-size: 14px auto;
}

.form-control::placeholder {
  color: #CBD5E0;
}

.form-control, .input-group-text {
  background: none;
  border-radius: 0;
  box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
  padding: 8px 4px 8px 0px;
  font-size: 18px;
}

.form-control.is-invalid, .input-group-text.is-invalid {
  box-shadow: inset 0px -1px 0px #E21F39;
}

.vue-tel-input {
  border: none;
}

select.select-month {
  height: 100%;
  font-size: 18px;
  line-height: 24px;
  border: none;
  box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;
  background-image: url("../../../assets/icon/select-arrow.png");
  background-repeat: no-repeat;
  background-size: 14px auto;
}

.btn-gender {
  padding: 4px 4px 4px 12px;
  border-radius: 8px;
  background: #EFF0F7;
  color: #4E4B66;
  width: 100%;
}

.btn-gender.active {
  background: #3566FC;
  color: #FFFFFF;
}

.box-icon {
  width: 30px;
  height: 30px;
  color: #4A5568;
  background: #FFFFFF;
}

.box-icon.active {
  color: #FFFFFF;
  background: rgba(255, 255, 255, 0.35);
}

input[type="radio"] {
  display: none;
  position: absolute;
}

.form-check {
  padding-left: unset;
  display: flex;
}

.form-check .form-check-input {
  margin-left: 0;
  margin-top: 0;
}

.input-group>.form-control.form-error {
  background: #FFDDEB;
  color: #14142B;
}

.frame-image {
  position: absolute;
  background: #007a9826;
  width: 56%;
}

.frame-image img {
  width: 90%;
  height: auto;
}

@media (min-width: 360px) and (max-width: 767px) {
  .col-md-5 {
      padding-right: 0px !important;
  }

  .form-login {
      padding-right: 1rem !important;
  }

  .col-md-7 {
      padding-left: 0px;
  }

  .frame-image {
      position: relative;
      background: #007a9826;
      width: 100%;
  }
}

.invalid-feedback.large {
  font-size: 18px;
}

.form-control {
  &.input-note {
    border-radius: 12px;
    padding: 8px 14px;
  }
}
</style>
