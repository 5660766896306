<template>
  <div id="main-header" class="mb-3">
    <div class="info container px-3">
      <div class="row">
        <div class="col">
          <div class="card shadow-sm border-0">
            <div class="card-body row p-0">
              <router-link
                class="info-group col d-flex align-items-center"
                :to="{ name: 'Voucher' }"
              >
                <div class="icon-wrapper d-flex align-items-center justify-content-center bg-info-500 rounded-circle">
                  <img
                    src="@/assets/icon/ticket-star.svg"
                    alt=""
                    class="icon"
                  />
                </div>
                <div class="value-wrapper d-flex flex-column">
                  <span class="value-wrapper-balance fw-700">0 Voucher</span>
                  <label class="value-wrapper-label fw-400 color-primary-500">Total Voucher</label>
                </div>
              </router-link>
              <router-link
                to='/membership'
                class="info-group col d-flex align-items-center"
              >
                <div class="icon-wrapper d-flex align-items-center justify-content-center bg-iris-100 rounded-circle">
                  <img
                    src="@/assets/icon/lovely.svg"
                    alt=""
                    class="icon"
                  />
                </div>
                <div class="value-wrapper d-flex flex-column">
                  <div class="value-wrapper-point d-flex align-items-end fw-700 fs-12 lh-14 gap-1">
                    {{ totalPoint }}
                    <span class="value-wrapper-point-label fw-400 text-gray-3">Point</span>
                  </div>
                  <label class="value-wrapper-label fw-400 color-primary-500">Daftar Reward</label>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { user } from '@/core/auth'
import { totalPoint, getTotalPoint } from '@/core/membership'
import { onMounted } from 'vue'

export default ({
  setup () {
    onMounted(async () => {
      await getTotalPoint()
    })

    return {
      user,
      totalPoint
    }
  }
})
</script>

<style lang="scss" scoped>
#main-header {
  @media (min-width: 768px) {
    height: auto;
  }
  position: relative;

  .card {
    @media (min-width: 768px) {
      background: #007A98;
      color: white;
    }

    .value-wrapper-point-label, .value-wrapper-label {
      @media (min-width: 768px) {
        color: white;
      }
    }
  }

  .content {
    position: absolute;
    z-index: 2;
    height: 80px;

    .row {
      @media (max-width: 580px) {
        height: 100%;
      }

      .content-name {
        .content-name-value {
          font-size: 14px;
          line-height: 18px;
        }

        .content-name-desc {
          font-size: 12px;
          line-height: 14px;
        }
      }

      .content-image {
        .icon-bell {
          width: 24px;
          height: 24px;
        }

        .image-profile {
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  .info {
    @media (min-width: 768px) {
      position: relative;
    }
    margin-top: -20px;
    z-index: 3;
    bottom: 0;

    .card {
      padding: 12px;
      border-radius: 12px;

      .card-body {
        .info-group {
          gap: 8px;
          color: #212529 !important;
          text-decoration: none;

          .icon-wrapper {
            width: 32px;
            height: 32px;

            .icon {
              width: 16px;
              height: auto;
            }
          }

          .value-wrapper {
            gap: 2px;
            color: #1A202C;

            &-balance {
              font-size: 12px;
              line-height: 14px;

              @media (min-width: 768px) {
                color: white;
              }
            }

            &-point {
              &-label {
                font-size: 10px;
                line-height: 12px;
              }

              @media (min-width: 768px) {
                color: white;
              }
            }

            &-label {
              font-size: 10px;
              line-height: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
