<template>
  <div id="main-voucher">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center pt-3">
        <div class="title-content-package">PROMO</div>
        <router-link
          :to="{ name: 'Promo' }"
          class="text-muted fs-14 fw-lighter d-flex align-items-center gap-1"
        >
          <span>Lihat Semua</span>
          <svg width="6" height="12" viewBox="0 0 9 18" fill="#6c757d" xmlns="http://www.w3.org/2000/svg">
<path d="M0.910097 17.67C0.720097 17.67 0.530098 17.6 0.380098 17.45C0.0900977 17.16 0.0900977 16.68 0.380098 16.39L6.9001 9.87002C7.3801 9.39002 7.3801 8.61002 6.9001 8.13002L0.380098 1.61002C0.0900977 1.32002 0.0900977 0.84002 0.380098 0.55002C0.670098 0.26002 1.1501 0.26002 1.4401 0.55002L7.9601 7.07002C8.4701 7.58002 8.7601 8.27002 8.7601 9.00002C8.7601 9.73002 8.4801 10.42 7.9601 10.93L1.4401 17.45C1.2901 17.59 1.1001 17.67 0.910097 17.67Z" fill="#6c757d"/>
          </svg>
        </router-link>
      </div>
      <div class="row flex-nowrap overflow-auto pb-3 pb-md-5">
        <Loading v-if="loading.getPromo" />
        <Empty v-else-if="listPromo.length === 0" :message="'Saat ini belum ada promo untuk Anda.'"/>
        <div v-else v-for="promo in listPromo" :key="promo" class="col-7 col-md-4 pt-2 pb-2">
          <div class="card border-0 shadow" @click="handleOnClick(promo)">
            <div class="card-body p-0">
              <div class="d-flex flex-column">
                <div class="border-radius-10">
                  <img class="icon-vouchers" :src="promo.banner">
                </div>
                <div class="pt-2 pb-3 px-3">
                  <h5 class="card-title fw-700">
                    {{ promo.nama }}
                  </h5>

                  <div v-if="promo.status !== 'VOUCHER'" class="mt-3">
                    <div class="discount-price color-primary-400">
                      Rp. {{ parseInt(promo.harga).toLocaleString('id-ID') }}
                    </div>
                    <div class="desc-voucher fs-12 lh-22 mb-md-3">
                      Harga Asli Rp. {{ parseInt(promo.harga_coret).toLocaleString('id-ID') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPromo, listPromo, loading } from '@/core/promo'
import { onMounted } from 'vue'
import Loading from '@/components/utility/Loading'
import Empty from '@/components/utility/Empty'
import { analytics } from '@/core/firebase-messaging'
import { token } from '@/core/auth'
import { selectedPromo } from '@/core/subDivisions'
import { useRouter } from 'vue-router'
import { showNotif } from '@/core/utility'

export default {
  components: {
    Loading, Empty
  },

  setup () {
    const router = useRouter()

    const handleOnClick = (promo) => {
      analytics.logEvent('promo_clicked')

      // jika promo tidak aktif, tampilkan notif
      if (promo.status_aktif === 0 || promo.status_aktif === '0') {
        return showNotif({
          status: 'warning',
          message: 'Promo Belum dapat digunakan'
        })
      } else if (!token.value) {
        analytics.logEvent('need_to_login_first')
        router.push({ name: 'Login' })
      } else {
        if (promo.status === 'VOUCHER') {
          return router.push({
            name: 'VoucherDetail',
            params: {
              kode: promo.kode_voucher
            }
          })
        }
        selectedPromo.value = promo
        router.push({
          name: 'SelectBranch',
          query: {
            redirectTo: `/layanan/promo/sub/${promo.id_dt}`,
            type: 'promo'
          }
        })
      }
    }

    onMounted(async () => {
      await getPromo()
    })

    return {
      loading,
      listPromo,
      handleOnClick
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-vouchers {
  width: 100%;
  // height: 154px;
}

.card {
  border-radius: 10px;

  .card-title {
    @media (max-width: 580px) {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 2px;
    }
  }

  .desc-voucher {
    font-family: 'Inter', sans-serif;
    text-decoration: line-through;

    @media (max-width: 580px) {
      margin-bottom: 8px;
    }
  }

  img {
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .button-group {
    .btn {
      width: 100%;

      .btn-title {
        @media (max-width: 580px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}

.title-content-package {
  font-size: 22px;
  color: #1A202C;

  @media (max-width: 580px) {
    font-size: 16px;
  }
}
</style>
