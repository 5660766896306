<template>
    <div class="main-page">
        <div class="container">
            <div class="row" style="padding-top: 8%">
                <div class="col-md-3 border-end vh-100">
                    <div class="d-flex flex-column">
                       <div class="bd-highlight pb-3">
                            <div class="d-flex align-items-center">
                               <router-link :to="{name: 'Summary', params: {id_order: $route.params.id_order}}" class="pe-3 text-decoration-none pt-2 text-gray-1">
                                    <i class="ri-arrow-left-line ri-xl"></i>
                                </router-link>
                                <p class="text-gray-1 fm-poppins fst-normal fw-500 fs-24 lh-36 mb-0">Pembayaran</p>
                            </div>
                       </div>
                       <div class="bd-highlight w-100 py-2">
                           <div class="btn service-category active d-flex flex-row align-items-center p-3">
                               <div class="bd-highlight">
                                   <img src="@/assets/icon/payment/money-bag.png" alt="">
                               </div>
                               <div class="bd-highlight px-3">
                                    <p class="text-gray-3 fm-nunito fst-normal fw-600 fs-12 lh-16 mb-0">Dompet Digital</p>
                               </div>
                           </div>
                       </div>
                       <div class="bd-highlight w-100 py-2">
                           <div class="btn service-category d-flex flex-row align-items-center p-3">
                               <div class="bd-highlight">
                                   <img src="@/assets/icon/payment/financial.png" alt="">
                               </div>
                               <div class="bd-highlight px-3">
                                    <p class="text-gray-3 fm-nunito fst-normal fw-600 fs-12 lh-16 mb-0">Bank Transfer</p>
                               </div>
                           </div>
                       </div>
                        <div class="bd-highlight w-100 py-2">
                           <div class="btn service-category d-flex flex-row align-items-center p-3">
                               <div class="bd-highlight">
                                   <img src="@/assets/icon/payment/credit-card.png" alt="">
                               </div>
                               <div class="bd-highlight px-3">
                                    <p class="text-gray-3 fm-nunito fst-normal fw-600 fs-12 lh-16 mb-0">Kartu Kredit</p>
                               </div>
                           </div>
                       </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="d-flex flex-column">
                        <div class="bd-highlight">
                            <div class="group d-flex flex-column">
                                <div class="bd-highlight pt-0 pb-3">
                                    <p class="text-gray-2 fm-poppins fst-normal fs-18 fw-600 lh-27 mb-0">Dompet Digital</p>
                                </div>
                                <div class="bd-highlight py-2">
                                    <div class="voucher d-flex flex-row justify-content-between align-items-center p-2 pe-3 mb-3">
                                        <div class="bd-highlight py-1">
                                            <div class="d-flex flex-row align-items-center">
                                                <div class="bd-highlight px-2">
                                                    <img src="@/assets/icon/payment/ovo.png" alt="">
                                                </div>
                                                <div class="bd-highlight px-2">
                                                    <div class="d-flex flex-column">
                                                        <div class="bd-highlight">
                                                            <p class="text-gray-1 fm-poppins fst-normal fw-500 fs-14 lh-14 mb-0">OVO</p>
                                                        </div>
                                                        <div class="bd-highlight pt-2">
                                                            <p class="text-gray-3 fm-nunito fst-normal fw-normal fs-12 lh-12 mb-0">OVO Cash Rp. 1.000.000</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bd-highlight pt-1">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="voucher d-flex flex-row justify-content-between align-items-center p-2 pe-3 mb-3">
                                        <div class="bd-highlight py-1">
                                            <div class="d-flex flex-row align-items-center">
                                                <div class="bd-highlight px-2">
                                                    <img src="@/assets/icon/payment/gopay.png" alt="">
                                                </div>
                                                <div class="bd-highlight px-2">
                                                    <div class="d-flex flex-column">
                                                        <div class="bd-highlight">
                                                            <p class="text-gray-1 fm-poppins fst-normal fw-500 fs-14 lh-14 mb-0">Gopay</p>
                                                        </div>
                                                        <div class="bd-highlight pt-2">
                                                            <p class="text-gray-3 fm-nunito fst-normal fw-normal fs-12 lh-12 mb-0">Saldo Rp. 1.000.000</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bd-highlight pt-1">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                            </div>
                                            <!-- <div class="form-check">
                                                <input type="radio" class="custom-control-input">
                                            </div> -->
                                        </div>
                                    </div>
                                     <div class="voucher d-flex flex-row justify-content-between align-items-center p-2 pe-3 mb-3">
                                        <div class="bd-highlight py-1">
                                            <div class="d-flex flex-row align-items-center">
                                                <div class="bd-highlight px-2">
                                                    <img src="@/assets/icon/payment/linkaja.png" alt="">
                                                </div>
                                                <div class="bd-highlight px-2">
                                                    <div class="d-flex flex-column">
                                                        <div class="bd-highlight">
                                                            <p class="text-gray-1 fm-poppins fst-normal fw-500 fs-14 lh-14 mb-0">Linkaja</p>
                                                        </div>
                                                        <div class="bd-highlight pt-2">
                                                            <p class="text-gray-3 fm-nunito fst-normal fw-normal fs-12 lh-12 mb-0">Saldo Rp. 1.000.000</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bd-highlight pt-1">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bd-highlight py-2">
                            <div class="group d-flex flex-column">
                                <div class="bd-highlight py-2">
                                    <p class="text-gray-2 fm-poppins fst-normal fs-18 fw-600 lh-27 mb-0">Ringkasan Pemesanan</p>
                                </div>
                                <div class="bd-highlight py-2">
                                    <div class="d-flex flex-row align-items-center justify-content-between">
                                        <div class="bd-highlight">
                                            <p class="fst-normal fw-normal text-gray-3 fs-14 lh-19 mb-0 fm-nunito">Total Tagihan</p>
                                        </div>
                                        <div class="bd-highlight">
                                            <p class="fst-normal  fw-bold text-gray-1 fs-14 lh-19 mb-0 fm-nunito">{{ (detail.services) ? 'Rp. ' + detail.services.reduce((acc, item) => acc + parseInt(item.harga), 0):'Loading ...' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="bd-highlight py-2">
                                    <div class="d-flex flex-row align-items-center justify-content-between">
                                        <div class="bd-highlight">
                                            <p class="fst-normal fw-normal text-gray-3 fs-14 lh-19 mb-0 fm-nunito">OVO Cash Terpakai</p>
                                        </div>
                                        <div class="bd-highlight">
                                            <p class="fst-normal  fw-bold text-gray-1 fs-14 lh-19 mb-0 fm-nunito">- Rp. 200</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="bd-highlight py-2">
                                    <div class="d-flex flex-row align-items-center justify-content-between">
                                        <div class="bd-highlight">
                                            <p class="fst-normal fw-normal text-gray-3 fs-14 lh-19 mb-0 fm-nunito">Voucher</p>
                                        </div>
                                        <div class="bd-highlight">
                                            <p class="fst-normal  fw-bold text-gray-1 fs-14 lh-19 mb-0 fm-nunito">Rp. 0</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="bd-highlight py-2">
                                    <div class="d-flex flex-row align-items-center justify-content-between">
                                        <div class="bd-highlight">
                                            <p class="fst-normal fw-normal text-gray-3 fs-14 lh-19 mb-0 fm-nunito">Biaya Lainnya</p>
                                        </div>
                                        <div class="bd-highlight">
                                            <p class="fst-normal  fw-bold text-gray-1 fs-14 lh-19 mb-0 fm-nunito">Rp. 0</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bd-highlight">
                            <div class="voucher d-flex flex-row justify-content-between align-items-center p-2">
                                <div class="bd-highlight py-1">
                                    <div class="d-flex flex-row align-items-center">
                                        <div class="bd-highlight px-2">
                                            <img src="@/assets/icon/icon-voucher.png" alt="">
                                        </div>
                                        <div class="bd-highlight px-2">
                                            <p class="text-gray-3 fm-poppins fst-normal fw-600 fs-12 lh-18 mb-0">Voucher STAYSAFE</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="bd-highlight py-1 pe-2">
                                    <img src="@/assets/icon/icon-right.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="card card-booking p-2 border-0">
                        <div class="card-body">
                            <div class="d-flex flex-column">
                                <div class="bd-highlight">
                                        <div class="booking-id d-flex flex-row justify-content-between align-items-center px-3 py-2">
                                            <div class="bd-highlight">
                                                <div class="d-flex flex-row align-items-center">
                                                    <div class="bd-highlight">
                                                        <img width="20" height="20" src="@/assets/tagar-icon.png" alt="">
                                                    </div>
                                                    <div class="bd-highlight px-2">
                                                        <p class="text-gray-3 fm-poppins fst-normal fw-500 fs-12 lh-18 mb-0 pt-1">Booking ID</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="bd-highlight">
                                                <p class="text-gray-1 fm-nunito fst-normal fw-bold fs-14 lh-14 mb-0">{{ $route.params.id_order }}</p>
                                            </div>
                                        </div>
                                </div>
                                <div class="bd-highlight py-3">
                                    <p class="text-gray-1 fm-poppins fst-normal fw-500 fs-14 lh-14 mb-0 py-3">Daftar Pasien</p>
                                    <div class="d-flex flex-column">
                                        <div
                                          v-for="patient in detail.patients"
                                          :key="patient"
                                        >
                                            <div class="bd-highlight d-flex flex-row align-items-center">
                                                <img class="pe-2" src="@/assets/Icon.png" alt="">
                                                <p class="text-gray-1 fm-poppins fst-normal fw-500 fs-14 lh-14 mb-0 pt-1 pb-2 pe-2">{{ patient.ap_nama }}</p>
                                            </div>
                                            <div class="divider">
                                                <hr class="border bg-gray-6">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="bd-highlight pt-5">
                                    <router-link to event>
                                        <div class="btn btn-pay bg-warning w-100 pt-2 pb-2">
                                            <p class="text-white fm-nunito fst-normal fw-600 fs-14 lh-19 m-0">Bayar</p>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { detail, getDetail } from '@/core/profile'

export default {
  setup () {
    if (detail.value.length === 0) {
      getDetail({
        kode: useRoute().params.id_order
      })
    }

    return {
      detail
    }
  }
}
</script>

<style scoped>
    .service-category.active {
        background: #007A98;
        border-radius: 10px;
    }

    .service-category.active p {
        color: #ffffff;
    }

    /* .custom-control-input {
        width: 1.5rem;
        height: 1.5rem;
        background-color: #8CC63F !important;
    } */

    .voucher {
        border: 1px solid #F2F2F2;
        box-sizing: border-box;
        border-radius: 10px;
    }

    .card-summary-order {
        border: 1px solid #F2F2F2;
        box-sizing: border-box;
        border-radius: 10px;
    }

    .card-booking {
        box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
    }

    .booking-id {
        border: 1px solid #F2F2F2;
        box-sizing: border-box;
        border-radius: 10px;
    }

    .btn-pay {
        border-radius: 8px;
    }

</style>
