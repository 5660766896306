import { reactive, ref, watch } from 'vue'
import axios from '../plugins/axios'
import { analytics } from './firebase-messaging'
import { checkVoucher, checkVoucherDouble, checkVoucherPenggunaBaru } from './cart'
import { showNotif } from './utility'
import { user, kode_promo } from './auth'
import { ORDER_COUNT } from './profile'
import { selectedMember } from './service'

export const bestSellers = ref([])
export const listCabangTerdekat = ref([])
export const listHomeService = ref([])
export const selectedBranch = ref(
  JSON.parse(localStorage.getItem('selectedBranchHomeService')) || null
)
export const biayaTambahan = ref(0)
export const loading = reactive({
  homeService: false,
  bestSeller: false
})
export const cart = ref({
  ap_nombayar: 0,
  ap_catatan: '',
  asal_data: 'WEB',
  status_bayar: 0, // 0 = online, 1 = offline
  cabang_periksa: 0,
  arr_paket: [],
  status_periksa: 1,
  pas_id_pendaftar: 0,
  id_pendaftar: 0,
  pas_id_periksa: 0,
  kode_voucher: '',
  nominal_voucher: 0,
  anamnesa_keluahan: '',
  anamnesa_jam_makan_terakhir: '',
  anamnesa_obat_yg_dikonsumsi: '',
  anamnesa_riwayat_penyakit: '',
  anamnesa_riwayat_alergi: '',
  anamnesa_penyakit_keluarga: '',
  anamnesa_kebiasaan_merokok: 'TIDAK', // YA, TIDAK
  print_out: 0, // 0 = tidak, 1 = ya
  dikirim_status: 0, // 0 = ambil sendiri, 1 = dikirim
  dikirim_alamat: '',
  // Tambahan data untuk home service
  tgl_hs: '',
  jam_hs: '',
  alamat_lengkap_hs: '',
  biaya_hs: 0,
  biaya_tambahan_hs: 0,
  latitude: '-7.2707524113856925',
  longitude: '112.75849436691482'
})
export const diskon = ref('')
export const diskonPenggunaBaru = reactive({
  nominal: 0,
  message: ''
})

watch(selectedBranch, () => {
  localStorage.setItem('selectedBranchHomeService', JSON.stringify(selectedBranch.value))
  analytics.logEvent('select_branch_home_service', selectedBranch.value)
})

export const getBestSeller = async () => {
  try {
    loading.bestSeller = true
    const { data } = await axios.get('home_service/best_seller', {
      params: {
        cabang: selectedBranch.value.id,
        latitude: selectedBranch.value.latitude,
        longitude: selectedBranch.value.longitude
      }
    })

    if (data.success) {
      bestSellers.value = data.data
      return
    }

    console.error('Error dari home service get bestSellers = ', data.message)
    // Reset datanya kalau error
    bestSellers.value = []
    throw data
  } catch (error) {
    console.error('Error dari home service get bestSellers = ', error)
    bestSellers.value = []
  } finally {
    loading.bestSeller = false
  }
}

export const getCabangTerdekat = async (lat, lng) => {
  try {
    loading.homeService = true
    const { data } = await axios.post('home_service/cari_cabang', {
      latitude: lat,
      longitude: lng
    })

    if (data.success) {
      listCabangTerdekat.value = data.data
    } else {
      listCabangTerdekat.value = []
    }
  } catch (error) {
    console.error('error get list cabang home service = ', error)
    throw error
  } finally {
    loading.homeService = false
  }
}

export const getHomeService = async (cabang, nama = '') => {
  try {
    loading.homeService = true
    const { data } = await axios.get('home_service/list_fpp_detail_all', {
      params: {
        cabang: cabang,
        nama: nama
      }
    })

    if (data.success) {
      listHomeService.value = data.data
    }
  } catch (error) {
    console.error('error get list home service = ', error)
    throw error
  } finally {
    loading.homeService = false
  }
}

export const clearVoucher = () => {
  cart.value.kode_voucher = ''
  cart.value.nominal_voucher = diskonPenggunaBaru.nominal
  diskon.value = ''
  kode_promo.value = ''
  analytics.logEvent('clear_voucher')
}

export const countVoucher = async () => {
  try {
    // Prepare var
    let functionCheckVoucher = checkVoucher

    // reset value voucher dulu
    cart.value.nominal_voucher = 0

    // pertama, cek apakah pengguna baru
    if (parseInt(user.value.pasien_baru)) {
      // Ganti function untuk hitung voucher nya
      functionCheckVoucher = checkVoucherDouble

      // hitung diskon pengguna baru
      const resultPenggunaBaru = await checkVoucherPenggunaBaru({
        id: user.value.id,
        nominal: cart.value.ap_nombayar,
        asal_data: 'WEB',
        arr_paket: cart.value.arr_paket
      })

      if (resultPenggunaBaru.jenis_diskon === 'rp') {
        diskonPenggunaBaru.message = 'Diskon pengguna baru'
        diskonPenggunaBaru.nominal = 'Rp. ' + resultPenggunaBaru.val
        cart.value.nominal_voucher = parseInt(resultPenggunaBaru.val)
      } else if (resultPenggunaBaru.jenis_diskon === '%') {
        diskonPenggunaBaru.message = 'Diskon 5% untuk pengguna baru'
        diskonPenggunaBaru.nominal = resultPenggunaBaru.diskon
        cart.value.nominal_voucher = parseInt(resultPenggunaBaru.diskon)
      }
    }

    // Kalau input kode voucher
    if (cart.value.kode_voucher) {
      const result = await functionCheckVoucher({
        kode_voucher: cart.value.kode_voucher,
        nominal: cart.value.ap_nombayar,
        asal_data: 'WEB',
        arr_paket: cart.value.arr_paket
      })

      if (result.jenis_diskon === 'rp') {
        diskon.value = 'Rp. ' + result.val
        cart.value.nominal_voucher += parseInt(result.val)
      } else if (result.jenis_diskon === '%') {
        diskon.value = result.diskon
        cart.value.nominal_voucher += parseInt(result.diskon)
      }

      analytics.logEvent('kode_voucher', result)
    } else {
      clearVoucher()
    }
  } catch (error) {
    // Reset diskon dan total new
    clearVoucher()
    showNotif({
      status: 'error',
      message: error.response.data.message ?? error.response.data.error
    })
  }
}

export const removeFromCart = async (item) => {
  const itemIndex = cart.value.arr_paket.findIndex(_item => _item.id_dt === item.id_dt)

  if (itemIndex > -1) {
    cart.value.arr_paket.splice(itemIndex, 1) // Hapus dari cart
    cart.value.ap_nombayar -= item.harga // Kurangi harganya
    await countVoucher() // Hitung ulang voucher
    localStorage.setItem('cartHomeService', JSON.stringify(cart.value)) // Update localStorage

    const trackingObject = {
      items: [{
        item_id: item.kode,
        item_name: item.nama,
        price: parseFloat(item.harga),
        currency: 'IDR',
        item_category: item.group,
        item_list_name: `${item.group} cabang ${selectedBranch.value?.nama}`,
        item_list_id: `${item.id_group}_cabang_${selectedBranch.value?.id}`,
        quantity: 1
      }],
      value: parseFloat(item.harga),
      currency: 'IDR'
    }

    console.log(trackingObject)

    analytics.logEvent('remove_from_cart', trackingObject)
  }
}

export const addToCart = (payload, group) => {
  // Cek di localStorage dulu
  const currentCart = JSON.parse(localStorage.getItem('cartHomeService'))

  // Jika ada, pakai dari localStorage
  if (currentCart) {
    cart.value = currentCart
  }

  // prevent duplikat
  if (cart.value.arr_paket.find(_item => _item.kode === payload.kode)) {
    console.log('produk duplikat, dihapus saja')
    removeFromCart(payload)
    return
  }

  // Update isi cart
  cart.value.ap_nombayar += parseInt(payload.harga)
  cart.value.cabang_periksa = parseInt(selectedBranch.value.id)
  cart.value.biaya_tambahan_hs += parseInt(payload.biaya_tambahan_hs)

  // tambahkan informasi grup
  payload.group = group

  // Push pesanan
  cart.value.arr_paket.push(payload)

  // update localStorage nya
  localStorage.setItem('cartHomeService', JSON.stringify(cart.value))

  const trackingObject = {
    items: [{
      item_id: payload.kode,
      item_name: payload.nama,
      price: parseFloat(payload.harga),
      currency: 'IDR',
      quantity: 1
    }],
    value: parseFloat(payload.harga),
    currency: 'IDR'
  }

  analytics.logEvent('add_to_cart', trackingObject)
}

export const getCart = () => {
  // Ambil data dari localStorage
  const currentCart = JSON.parse(localStorage.getItem('cartHomeService'))
  if (currentCart) {
    cart.value = currentCart
  }
}

export const clearCart = () => {
  // hapus dari localStorage
  localStorage.removeItem('cartHomeService')

  // reset diskon
  diskon.value = ''

  // reset reactive cart
  cart.value = {
    ap_nombayar: 0,
    ap_catatan: '',
    asal_data: 'WEB',
    status_bayar: 0, // 0 = online, 1 = offline
    cabang_periksa: 0,
    arr_paket: [],
    status_periksa: 1,
    pas_id_pendaftar: 0,
    id_pendaftar: 0,
    pas_id_periksa: 0,
    kode_voucher: '',
    nominal_voucher: 0,
    anamnesa_keluahan: '',
    anamnesa_jam_makan_terakhir: '',
    anamnesa_obat_yg_dikonsumsi: '',
    anamnesa_riwayat_penyakit: '',
    anamnesa_riwayat_alergi: '',
    anamnesa_penyakit_keluarga: '',
    anamnesa_kebiasaan_merokok: 'TIDAK', // YA, TIDAK
    print_out: 0, // 0 = tidak, 1 = ya
    dikirim_status: 0, // 0 = ambil sendiri, 1 = dikirim
    dikirim_alamat: '',
    // Tambahan data untuk home service
    tgl_hs: '',
    jam_hs: '',
    alamat_lengkap_hs: '',
    biaya_hs: 0,
    biaya_tambahan_hs: 0,
    latitude: '-7.2707524113856925',
    longitude: '112.75849436691482'
  }

  analytics.logEvent('clear_cart')
}

export const submitOrder = async () => {
  try {
    loading.homeService = true

    // Isi data yang kurang
    cart.value.pas_id_pendaftar = user.value.pas_id
    cart.value.id_pendaftar = user.value.id
    cart.value.biaya_hs = parseInt(selectedBranch.value.biaya_hs)
    cart.value.ap_nombayar += cart.value.biaya_hs + cart.value.biaya_tambahan_hs

    if (cart.value.status_periksa === 1) {
      cart.value.pas_id_periksa = user.value.pas_id
    } else {
      cart.value.pas_id_periksa = selectedMember.pas_id_periksa
    }

    // Format tgl_periksa
    const tglPeriksa = new Date(cart.value.tgl_hs)
    cart.value.tgl_hs = tglPeriksa.getFullYear() + ('0' + (tglPeriksa.getMonth() + 1)).slice(-2) + ('0' + tglPeriksa.getDate()).slice(-2)

    const { data } = await axios.post('home_service/daftar', cart.value)

    const trackingObject = {
      items: cart.value.arr_paket.map(_item => ({
        item_id: _item.kode,
        item_name: _item.nama,
        price: parseFloat(_item.harga),
        currency: 'IDR',
        item_category: _item.group,
        item_list_name: `${_item.group} cabang ${selectedBranch.value?.nama}`,
        item_list_id: `${_item.id_group}_cabang_${selectedBranch.value?.id}`,
        quantity: 1
      })),
      value: parseFloat(cart.value.ap_nombayar),
      currency: 'IDR',
      transaction_id: data.data.kode_daftar
    }

    analytics.logEvent('purchase', trackingObject)

    // Clear cart dulu baru return
    await clearCart(false) // tanpa notif sukses
    ORDER_COUNT.value++

    return data
  } catch (error) {
    console.error(error)
    throw error
  } finally {
    loading.homeService = false
  }
}
