<template>
  <div class="container">
    <app-bar
      title="Detail Voucher"
      :previousLink="{ name: 'Home' }"
    />
    <Loading v-if="loading.getVoucherDetail" />
    <Empty v-else-if="!voucherDetail" :message="'Detail voucher tidak dapat ditemukan.'"/>
    <div v-else class="row table-container">
      <table class="table table-pemeriksaan">
        <tbody>
          <tr class="item-result fs-12 color-neutral-700">
            <td class="px-3">Event</td>
            <td class="px-3"> <span class="colon"></span> {{ voucherDetail.event }}</td>
          </tr>
          <tr class="item-result fs-12 color-neutral-700">
            <td class="px-3">Tanggal Berlaku</td>
            <td class="px-3"> <span class="colon"></span> {{ voucherDetail.tgl_berlaku }}</td>
          </tr>
          <tr class="item-result fs-12 color-neutral-700">
            <td class="px-3">Minimal Transaksi</td>
            <td class="px-3"> <span class="colon"></span> {{ voucherDetail.minimal_transaksi }}</td>
          </tr>
          <tr class="item-result fs-12 color-neutral-700">
            <td class="px-3">Diskon</td>
            <td class="px-3"> <span class="colon"></span> {{ voucherDetail.diskon }}</td>
          </tr>
        </tbody>
      </table>

      <div v-if="voucherDetail.berlaku_selain_pemeriksaan">
        <span class="fs-14">Voucher Berlaku Selain Pemeriksaan</span>
        <ul>
          <li class="text-muted" v-for="selainPemeriksaan in voucherDetail.berlaku_selain_pemeriksaan" :key="selainPemeriksaan.nama_pemeriksaan">
            {{ selainPemeriksaan.nama_pemeriksaan }}
          </li>
        </ul>
      </div>

      <div v-if="voucherDetail.berlaku_hanya_pemeriksaan">
        <span class="fs-14">Voucher Berlaku Hanya Pemeriksaan</span>
        <ul>
          <li class="text-muted" v-for="hanyaPemeriksaan in voucherDetail.berlaku_hanya_pemeriksaan" :key="hanyaPemeriksaan.nama_pemeriksaan">
            {{ hanyaPemeriksaan.nama_pemeriksaan }}
          </li>
        </ul>
      </div>

      <div class="bottom-bar col-md-5 mb-0 mb-md-3 mb-xs-3">
        <div class="btn-wrapper py-3">
          <button
            class="btn btn-primary rounded-pill w-100"
            @click="prepareClaim"
          >
            <span class="fw-600 fs-14 lh-20">Klaim</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Empty from '@/components/utility/Empty'
import AppBar from '@/components/AppBar'
import Loading from '@/components/utility/Loading'
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getVoucherDetail, voucherDetail, loading, claimVoucher } from '@/core/voucher'
import { showNotif } from '@/core/utility'

export default {
  components: {
    Empty, AppBar, Loading
  },
  setup () {
    const route = useRoute()
    const router = useRouter()

    const prepareClaim = async () => {
      try {
        const result = await claimVoucher({
          device: 'WEB',
          kode_voucher: route.params.kode
        })

        if (result.status) {
          showNotif({
            status: 'success',
            message: result.message
          })

          return router.push({
            name: 'Home'
          })
        }
      } catch (error) {
        console.error('error klaim voucher = ', error)
      }
    }

    onMounted(async () => {
      await getVoucherDetail({ kode: route.params.kode })
    })

    return {
      loading,
      voucherDetail,
      prepareClaim
    }
  }
}
</script>

<style lang="scss" scoped>
.table-container {
  @media (max-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
  }

  .table-pemeriksaan tbody tr {
    border: hidden;
  }

  .table-pemeriksaan > tbody > .item-result:nth-child(2n+2) > td {
    background-color: #F7FAFC;
  }

  .colon::before {
    content: ':';
    margin-right: 5px;
  }
}
</style>
