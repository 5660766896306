<template>
  <div>
    <app-bar
      title="Kontak Parahita"
      :previousLink="{ name: 'Profil' }"
      :noDivider="true" />

    <div class="card mt-3 mt-md-0 rounded-3">
      <div class="card-body">
        <section>
          <small class="text-muted">Email</small>
          <p>
            <a :href="'mailto:' + contact.email">
              {{ contact.email }}
            </a>
          </p>
        </section>
        <section>
          <small class="text-muted">Telp</small>
          <p>
            <a href="javascript:void()">
              {{ contact.telp }}
            </a>
          </p>
        </section>
        <section>
          <small class="text-muted">Whatsapp</small>
          <p>
            <a :href="contact.wa" target="_blank">
              Chat Kami
            </a>
          </p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import { nextTick } from 'vue'
import { title } from '@/core/page'
import { contact } from '@/core/profile'

export default {
  components: { AppBar },
  setup () {
    nextTick(() => {
      title.value = 'Kontak'
    })

    return {
      contact
    }
  }
}
</script>
