<template>
  <!-- Akun -->
  <nav class="menu mt-3 mt-md-2 mb-3">
    <h5 class="nav-title fw-700 fs-18 color-neutral-900">Akun</h5>
    <router-link
      :to="{ name: 'SettingPersonalData' }"
      class="d-flex align-items-center justify-content-between sub-menu"
    >
      <div>
        <img src="@/assets/icon/profile/data-diri.svg" alt="Data diri">
        <span class="fs-12 fw-700 color-neutral-600 ms-3">Data Diri</span>
      </div>
      <img
        src="@/assets/icon/arrow-right-outline.svg"
        alt="Icon"
      />
    </router-link>
    <router-link
      :to="{ name: 'SettingAnggotaKeluarga' }"
      class="d-flex align-items-center justify-content-between sub-menu"
    >
      <div>
        <img src="@/assets/icon/profile/undang-teman.svg" alt="Anggota Keluarga">
        <span class="fs-12 fw-700 color-neutral-600 ms-3">Anggota Keluarga</span>
      </div>
      <img
        src="@/assets/icon/arrow-right-outline.svg"
        alt="Icon"
      />
    </router-link>
    <router-link
      :to="{ name: 'SettingContact' }"
      class="d-flex align-items-center justify-content-between sub-menu"
    >
      <div>
        <img src="@/assets/icon/profile/kontak.svg" alt="Kontak">
        <span class="fs-12 fw-700 color-neutral-600 ms-3">Kontak</span>
      </div>
      <img
        src="@/assets/icon/arrow-right-outline.svg"
        alt="Icon"
      />
    </router-link>
    <router-link
      :to="{ name: 'Referal' }"
      class="d-flex align-items-center justify-content-between sub-menu"
    >
      <div>
        <img src="@/assets/icon/profile/data-diri.svg" alt="Kode Referal">
        <span class="fs-12 fw-700 color-neutral-600 ms-3">Kode Referal</span>
      </div>
      <img
        src="@/assets/icon/arrow-right-outline.svg"
        alt="Icon"
      />
    </router-link>
    <router-link
      :to="{ name: 'SettingPassword' }"
      class="d-flex align-items-center justify-content-between sub-menu"
    >
      <div>
        <img src="@/assets/icon/profile/kata-sandi.svg" alt="Kata Sandi">
        <span class="fs-12 fw-700 color-neutral-600 ms-3">Kata Sandi</span>
      </div>
      <img
        src="@/assets/icon/arrow-right-outline.svg"
        alt="Icon"
      />
    </router-link>
    <!-- <router-link
      :to="'#'"
      class="d-flex align-items-center justify-content-between sub-menu"
    >
      <div>
        <img src="@/assets/icon/profile/pusat-bantuan.svg" alt="Pusat Bantuan">
        <span class="fs-12 fw-700 color-neutral-600 ms-3">Pusat Bantuan</span>
      </div>
      <img
        src="@/assets/icon/arrow-right-outline.svg"
        alt="Icon"
      />
    </router-link>
    <router-link
      :to="'#'"
      class="d-flex align-items-center justify-content-between sub-menu"
    >
      <div>
        <img src="@/assets/icon/profile/undang-teman.svg" alt="Ajak teman pakai Parahita">
        <span class="fs-12 fw-700 color-neutral-600 ms-3">Ajak teman pakai Parahita</span>
      </div>
      <img
        src="@/assets/icon/arrow-right-outline.svg"
        alt="Icon"
      />
    </router-link> -->
    <router-link
      :to="{ name: 'Notifications' }"
      class="d-flex align-items-center justify-content-between sub-menu"
    >
      <div>
        <img src="@/assets/icon/profile/notifikasi.svg" alt="Notifikasi">
        <span class="fs-12 fw-700 color-neutral-600 ms-3">
          Notifikasi
          <span class="badge bg-secondary ms-2">{{ notificationCount }}</span>
        </span>
      </div>
      <img
        src="@/assets/icon/arrow-right-outline.svg"
        alt="Icon"
      />
    </router-link>
    <!-- <router-link
      :to="{ name: 'ProfilKontak' }"
      class="d-flex align-items-center justify-content-between sub-menu"
    >
      <div>
        <img src="@/assets/icon/profile/data-diri.svg" alt="Data diri">
        <span class="fs-12 fw-700 color-neutral-600 ms-3">Kontak Parahita</span>
      </div>
      <img
        src="@/assets/icon/arrow-right-outline.svg"
        alt="Icon"
      />
    </router-link> -->
    <div
      class="d-flex align-items-center justify-content-between sub-menu"
      role="button"
      @click="logout"
    >
      <div>
        <img src="@/assets/icon/profile/logout.svg" alt="Keluar">
        <span class="fs-12 fw-700 color-neutral-600 ms-3">Keluar</span>
      </div>
      <img
        src="@/assets/icon/arrow-right-outline.svg"
        alt="Icon"
      />
    </div>
  </nav>

  <!-- Kebijakan -->
  <!-- <nav class="menu mt-3 mt-md-2">
    <h5 class="nav-title fw-700 fs-18 color-neutral-900">Kebijakan</h5>
    <router-link
      :to="'#'"
      class="d-flex align-items-center justify-content-between sub-menu"
    >
      <div>
        <img src="@/assets/icon/profile/kebijakan-privasi.svg" alt="Kebijakan Privasi">
        <span class="fs-12 fw-700 color-neutral-600 ms-3">Kebijakan Privasi</span>
      </div>
      <img
        src="@/assets/icon/arrow-right-outline.svg"
        alt="Icon"
      />
    </router-link>
    <router-link
      :to="'#'"
      class="d-flex align-items-center justify-content-between sub-menu"
    >
      <div>
        <img src="@/assets/icon/profile/ketentuan-layanan.svg" alt="Ketentuan Layanan">
        <span class="fs-12 fw-700 color-neutral-600 ms-3">Ketentuan Layanan</span>
      </div>
      <img
        src="@/assets/icon/arrow-right-outline.svg"
        alt="Icon"
      />
    </router-link>
    <router-link
      :to="'#'"
      class="d-flex align-items-center justify-content-between sub-menu"
    >
      <div>
        <img src="@/assets/icon/profile/rating.svg" alt="Beri Penilaian">
        <span class="fs-12 fw-700 color-neutral-600 ms-3">Beri Penilaian</span>
      </div>
      <img
        src="@/assets/icon/arrow-right-outline.svg"
        alt="Icon"
      />
    </router-link>
  </nav> -->
</template>

<script>
import { logout } from '@/core/auth'
import { notificationCount } from '@/core/notifications'
import { nextTick, onMounted } from '@vue/runtime-core'
import { title } from '@/core/page'

export default {
  setup () {
    onMounted(() => {
      nextTick(() => {
        title.value = 'Profil'
      })
    })

    return {
      logout,
      notificationCount
    }
  }
}
</script>

<style lang="scss" scoped>
nav.menu .sub-menu {
  box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
  padding: 12px 0px;
}
</style>
