<template>
    <div id="main-page">
        <div class="container">
          <app-bar
            title="Pilih Cabang"
            previous-link="/" />
          <div class="row">
            <div class="col-md-4">
              <div class="input-group mb-3 rounded-pill">
                <input
                  type="text"
                  class="form-control bg-transparent"
                  placeholder="Cari cabang...."
                  name="search"
                  v-model="keyword"
                  autocomplete="off">
                <span class="input-group-text bg-transparent border-0">
                    <div
                      class="box-icon"
                      type="button"
                      @click="keyword = ''"
                    >
                      <i class="ri-close-circle-fill fs-14 color-neutral-700"></i>
                    </div>
                </span>
                </div>
            </div>
          </div>
          <Loading v-if="loading.subDivisions && filteredSubDivisions.length < 1" />
          <div
            v-else-if="filteredSubDivisions.length">
            <div class="row">
              <div
                v-for="(data, index) in filteredSubDivisions"
                class="col-md-4 mb-md-3"
                :key="index">
                <a
                  href="javascript:void(0)"
                  class="branch rounded d-flex justify-content-between align-items-center py-2 mb-md-2"
                  >
                  <div class="branch-group d-flex align-items-center">
                    <div
                      class="icon-wrapper bg-neutral-200 d-flex align-items-center justify-content-center rounded-circle"
                      :class="{ [data.id === selectedBranch?.id ? 'bg-primary' : '']: true }"
                    >
                      <img
                        :src="data.id === selectedBranch?.id ? require('@/assets/icon/location-white.png') : require('@/assets/icon/location.png')"
                        alt=""
                      />
                    </div>
                    <div class="branch-group-info d-flex flex-column ps-3">
                      <p class="branch-name fw-bold fs-14 lh-18 color-neutral-900 mb-0">
                        {{ data.nama }}
                      </p>
                      <p class="branch-address fw-400 fs-12 lh-14 mb-0 color-neutral-600">
                        {{ data.alamat }}
                      </p>
                      <small
                        class="branch-phone fw-400 fs-12 lh-14 color-neutral-600">
                        {{ data.tlp || '-' }}
                      </small>
                    </div>
                  </div>
                  <div class="branch-group d-flex align-items-center">
                    <input
                      type="radio"
                      name="branch"
                      class="input-select-branch"
                      :checked="data.id === selectedBranch?.id"
                      @click.prevent="openConfirmation(data)"
                    />
                  </div>
                </a>
              </div>
            </div>
            <div class="bottom-bar pb-md-3 p-2 px-md-0">
              <div class="row">
                <div class="col-md-4">
                  <button
                    @click="submit"
                    class="btn btn-orange text-white w-100 pt-2 pb-2"
                    :class="{
                      disabled: !selectedBranch || !isSelectedBranchAvailable()
                    }"
                  >
                    Lanjutkan
                  </button>
                  <!-- <router-link
                    :to="nextLink"
                    class="btn btn-orange text-white w-100 pt-2 pb-2"
                    :class="{
                      disabled: !selectedBranch
                    }">
                    Lanjutkan
                  </router-link> -->
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="not-found d-flex flex-column text-center pb-5 pt-5">
            <div class="image-wrapper">
              <img src="@/assets/icon/empty.png" alt="">
            </div>
            <p class="text-title-active fw-bold fs-18 lh-28">
              Tidak ada hasil ditemukan
            </p>
            <div class="text-body-light d-flex justify-content-center">
              <p class="fw-400 fs-14 lh-24 mb-0">Maaf cabang yang anda cari tidak ditemukan, silahkan coba layanan lainnya.</p>
            </div>
          </div>
        </div>
    </div>
    <!-- Modal -->
    <div class="modal fade"
    id="modal-confirmation"
    tabindex="-1"
    aria-labelledby="labelModalConfirmation" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body text-center d-flex flex-column py-3">
              <h5 class="modal-title" id="labelModalConfirmation">Konfirmasi</h5>
              <p>Mengganti cabang akan merubah harga sesuai dengan cabang baru yang dipilih, yakin?</p>
              <div class="group-button pb-2 pt-3">
                <button
                  type="button"
                  class="btn btn-secondary text-white w-25 me-1" data-bs-dismiss="modal">Tidak</button>
                <button
                  @click="changeBranch"
                  type="button" class="btn text-white btn-danger w-25 ms-1">
                  Ya
                </button>
              </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
</template>

<script>
import AppBar from '@/components/AppBar'
import Loading from '@/components/utility/Loading'
import { subDivisions, getSubDivisions, selectedBranch, loading, selectedPromo } from '@/core/subDivisions'
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue'
import { title } from '@/core/page'
import { useRoute, useRouter } from 'vue-router'
import { Modal } from 'bootstrap'
import { clearCart } from '@/core/cart'
import { showNotif } from '@/core/utility'
import { claimReward } from '@/core/membership'

export default {
  components: {
    AppBar, Loading
  },
  setup () {
    const keyword = ref('')
    const preSelectBranch = ref(null)
    const modal = ref(null)
    const route = useRoute()
    const router = useRouter()

    const filteredSubDivisions = computed(() => {
      return subDivisions.value ? subDivisions.value.filter((data) => {
        // kalau ada promo yg dilipih
        if (selectedPromo.value) {
          // pastikan cuman tampil cabang yg berlaku saja
          if (selectedPromo.value.cabang_berlaku.filter(_cabang => _cabang.id === data.id).length > 0) {
            return keyword.value.toLowerCase().split(' ').every(v => data.nama.toLowerCase().includes(v))
          }
        } else {
          return keyword.value.toLowerCase().split(' ').every(v => data.nama.toLowerCase().includes(v))
        }
      }) : []
    })

    const nextLink = (() => {
      if (route.query.id_layanan) {
        if (route.query.id_dt) {
          return {
            name: 'SubServiceDetail',
            params: {
              id_layanan: route.query.id_layanan,
              id_dt: route.query.id_dt
            }
          }
        }

        return {
          name: 'SubService',
          params: {
            id_layanan: route.query.id_layanan
          }
        }
      }

      return {
        path: route.query.redirectTo
      }
    })()

    const openConfirmation = (newBranch) => {
      // cek apakah branch yang dipilih beda dengan branch sekarang
      if (newBranch.id !== selectedBranch.value?.id) {
        // pre save branch baru
        preSelectBranch.value = newBranch

        // prepare modal
        modal.value = new Modal(
          document.querySelector('#modal-confirmation'), {
            backdrop: 'static'
          }
        )

        // trigger modal
        modal.value.show()
      }
    }

    const prepareClaimFree = async () => {
      try {
        const result = await claimReward({
          id_dt_member: route.query.id_dt_member,
          device: 'WEB',
          cabang: selectedBranch.value.id
        })

        if (result.status) {
          showNotif({
            status: 'success',
            message: 'Reward berhasil di claim / reedem'
          })

          await router.replace({
            name: 'ProfilOrderDetail',
            params: {
              id_order: result.data.kode
            }
          })
        } else {
          showNotif({
            status: 'error',
            message: result.message
          })
        }
      } catch (error) {
        console.error('error claim reward', error)
        showNotif({
          status: 'error',
          message: error.response?.data?.message ?? error
        })
      }
    }

    const isSelectedBranchAvailable = () => {
      let isEligible = false
      for (let i = 0; i < filteredSubDivisions.value.length; i++) {
        if (filteredSubDivisions.value[i].id === selectedBranch?.value?.id) {
          isEligible = true
          break
        }
      }
      return isEligible
    }

    const submit = async () => {
      // pastikan branch yg dipilih available
      if (!isSelectedBranchAvailable()) {
        return showNotif({
          status: 'error',
          message: 'Silahkan pilih cabang terlebih dahulu'
        })
      }

      if (route.query.redirectTo === 'membership') {
        return prepareClaimFree()
      }

      // lanjut ke page selanjutnya
      router.push(nextLink)
    }

    const changeBranch = async () => {
      // hide dulu modal nya
      modal.value.hide()

      // kosongkan semua cart dulu, biar cabangnya nda kereset wkwkwk
      await clearCart(false)

      // update branch sekarang
      selectedBranch.value = preSelectBranch.value

      // langsung submit
      submit()
    }

    onMounted(() => {
      if (route.query.type === 'promo' && !selectedPromo.value) {
        return router.push({
          name: 'Home'
        })
      }

      getSubDivisions()

      nextTick(() => {
        title.value = 'Pilih Cabang'
      })
    })

    onUnmounted(() => {
      // kalau keluar page null kan
      if (selectedPromo.value) {
        selectedPromo.value = null
      }
    })

    return {
      keyword,
      filteredSubDivisions,
      loading,
      selectedBranch,
      changeBranch,
      openConfirmation,
      nextLink,
      submit,
      selectedPromo,
      isSelectedBranchAvailable
    }
  }
}
</script>

<style lang="scss" scoped>
#main-page {
  @media (max-width: 520px) {
    padding-bottom: 3.25rem;
  }
}

.bottom-bar {
  @media (max-width: 520px) {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid rgb(240, 240, 240);
  }
}

.input-group {
  border: 1px solid #E2E8F0;

  .form-control, .input-group-text {
    height: 34px;
  }

  .form-control:focus{
    box-shadow: none;
  }

  .form-control::placeholder {
    color: #A0AEC0;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
}

.branch {
  box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;

  .icon-wrapper {
    width: 48px;
    height: 48px;
  }

  .branch-group-info {
    gap: 4px;

    .branch-address {
      @media (max-width: 540px) {
        display: block;
        width: 239px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .input-select-branch {
    width: 24px;
    height: 24px;
  }

  .input-select-branch:checked {
    accent-color: #007A98;
  }
}

.not-found {
  .image-wrapper {
    margin-bottom: 24px;
  }

  .text-title-active {
    margin-bottom: 4px;
  }

  .text-body-light p {
    width: 286px;
  }
}
</style>
