<template>
    <div id="main-page">
        <div class="container">
          <app-bar
            title="Home Service"
            previous-link="/" />
          <div class="row">
            <div class="gmap px-0">
              <GMapMap
                :center="center"
                :zoom="13"
                id="gmapHomeService"
                style="width: 100%; height: 100%"
                ref="gmapHomeService"
              >
                <GMapMarker
                  :position="center"
                  :draggable="true"
                  :icon="markerIcon"
                  @dragend="dragMarker"
                  ref="locationMarker"
                />
                <GMapMarker
                  :key="index"
                  v-for="(cabangTerdekat, index) in listCabangTerdekat"
                  :position="{ lat: parseFloat(cabangTerdekat.latitude), lng: parseFloat(cabangTerdekat.longitude) }"
                />
              </GMapMap>
            </div>
          </div>

          <div class="row">
            <div class="bottom-bar">
              <div v-if="isShowInfo" class="info d-flex flex-column align-items-center py-md-3">
                <div class="info-image py-3">
                  <img src="@/assets/icon/home-service.svg" alt="Icon">
                </div>
                <div class="info-group text-center mb-2">
                  <div class="info-group-title fw-700 fs-18 color-neutral-900 mb-2">
                    Layanan Home Service
                  </div>
                  <p class="info-group-desc fw-400 fs-14 color-neutral-600 mb-0" style="max-width: 255px">Layanan ini akan memudahkan anda melakukan pemeriksaan tanpa keluar rumah</p>
                </div>
                <div class="info-button w-100">
                    <router-link
                    class="btn btn-primary w-100"
                    :to="{
                      query: { showInfo: !isShowInfo }
                    }"
                  >
                    Oke Saya Mengerti
                  </router-link>
                </div>
              </div>

              <div v-else class="cabang pt-md-3">
                <div class="form-search input-group rounded-pill px-1 mb-3">
                  <GMapAutocomplete
                      placeholder="Cari lab parahita di area anda..."
                      class="form-control bg-transparent"
                      @place_changed="setPlace"
                    >
                  </GMapAutocomplete>
                  <span class="input-group-text bg-transparent border-0">
                    <img src="@/assets/icon/search-outline.svg" alt="Ikon pencarian">
                  </span>
                </div>
                <section
                  v-if="listCabangTerdekat && listCabangTerdekat.length < 1">
                  <div class="empty d-flex align-items-center gap-3 mb-3">
                    <img
                      src="@/assets/icon/empty.png"
                      alt="Icon Empty"
                      class="empty-icon">
                    <div class="empty-group">
                      <div class="fw-700 fs-14 color-neutral-900 mb-1">Klinik Tidak Ditemukan</div>
                      <p class="fw-400 fs-12 lh-22 color-neutral-600 mb-0">Sepertinya di area anda belum ada klinik terdekat</p>
                    </div>
                  </div>
                  <button
                    class="btn btn-primary w-100"
                    @click="detectLocation"
                  >
                    Coba Lagi
                  </button>
                </section>
                <section v-else class="px-0">
                  <p>
                    <strong class="fw-14 color-neutral-900">{{ listCabangTerdekat.length }} Klinik di area anda</strong>
                  </p>
                  <div class="list-cabang d-flex flex-column overflow-scroll">
                    <a
                      v-for="cabangTerdekat in listCabangTerdekat"
                      :key="cabangTerdekat.id"
                      class="item d-flex align-items-center justify-content-between rounded"
                      @click="pilihCabang(cabangTerdekat)"
                    >
                      <div class="item-group d-flex align-items-center gap-2">
                        <img class="item-image" :src="cabangTerdekat.icon">
                        <div class="d-flex flex-column">
                          <div class="item-name fw-700 fs-12 color-neutral-900">
                            {{ cabangTerdekat.nama }}
                          </div>
                          <small class="fw-400 fs-12 color-neutral-600">
                            {{ cabangTerdekat.alamat }}
                          </small>
                        </div>
                      </div>
                      <img
                        src="@/assets/icon/arrow-right-outline.svg"
                        alt="Icon"
                        class="icon-arrow"
                      />
                    </a>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import { nextTick, onMounted, ref, reactive, computed } from 'vue'
import { title } from '@/core/page'
import { getCabangTerdekat, listCabangTerdekat, selectedBranch } from '@/core/homeService'
import { useRoute, useRouter } from 'vue-router'

export default {
  components: {
    AppBar
  },
  setup () {
    const keyword = ref('')
    const gmapHomeService = ref(null)
    const locationMarker = ref(null)
    const router = useRouter()
    const route = useRoute()
    const center = reactive({
      lat: -7.256206907509911,
      lng: 112.75229864535952
    })
    const markerIcon = require('@/assets/icon/marker-user.svg')
    const pilihCabang = (cabang) => {
      // save cabang yg dipilih
      selectedBranch.value = cabang

      // redirect ke halaman selanjutnya
      router.push({ name: 'HomeServiceIndex' })
    }

    const setPlace = (location) => {
      if (location.geometry.viewport) {
        gmapHomeService.value.$mapObject.fitBounds(location.geometry.viewport)
      } else {
        gmapHomeService.value.$mapObject.setCenter(location.geometry.location)
        gmapHomeService.value.$mapObject.setZoom(17)
      }

      getCabangTerdekat(location.geometry.location.lat(), location.geometry.location.lng())

      // update center biar markernya ikut pindah
      center.lat = location.geometry.location.lat()
      center.lng = location.geometry.location.lng()
    }

    const dragMarker = (marker) => {
      getCabangTerdekat(marker.latLng.lat(), marker.latLng.lng())
    }

    const isShowInfo = computed(() => route.query.showInfo === 'true')

    const detectLocation = () => {
      navigator.geolocation.getCurrentPosition((success) => {
        center.lat = success.coords.latitude
        center.lng = success.coords.longitude
        getCabangTerdekat(center.lat, center.lng)
      }, (error) => {
        console.log(error)
        getCabangTerdekat(center.lat, center.lng)
      })
    }

    onMounted(() => {
      detectLocation()
      nextTick(() => {
        title.value = 'Pilih Cabang'
      })
    })

    return {
      listCabangTerdekat,
      selectedBranch,
      pilihCabang,
      keyword,
      setPlace,
      gmapHomeService,
      center,
      locationMarker,
      dragMarker,
      markerIcon,
      isShowInfo,
      detectLocation
    }
  }
}
</script>

<style lang="scss" scoped>
.gmap {
    height: 400px;
    width: 100%;
    color: white;
}

.empty {
  &-icon {
    width: 64px;
    aspect-ratio: 1 / 1;
  }
}

.list-cabang {
  gap: 12px;

  .item {
    box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;
    padding: 8px 0;

    &-image {
      width: 48px;
      aspect-ratio: 1 / 1;
      object-fit: contain;
    }

    .icon-arrow {
      width: 9px;
    }
  }

  .item:last-child {
    box-shadow: none;
  }
}

.form-search {
  border: 1px solid #E2E8F0;
  width: 400px;

  @media (max-width: 540px) {
    width: 100%;
  }

  .form-control, .input-group-text {
    height: 40px;
  }

  .form-control:focus{
    box-shadow: none;
  }

  .form-control::placeholder {
    color: #A0AEC0;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  }
}

.bottom-bar {
  @media (max-width: 520px) {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    // border-top: 1px solid rgb(240, 240, 240);
    border-radius: 20px 20px 0px 0px;
    padding: 18px;
  }
}
</style>
