import axios from '../plugins/axios'
import { ref, reactive } from 'vue'
import { showNotif } from '@/core/utility'
// import { analytics, getTokenAfterLogin } from './firebase-messaging'

export const listPromo = ref([])
export const loading = reactive({
  getPromo: false
})

export const getPromo = async () => {
  try {
    loading.getPromo = true
    const { data } = await axios.get('app/promo_v2')

    if (data.success === true) {
      listPromo.value = data.data
      return {
        status: true,
        message: 'Sukses'
      }
    }

    showNotif({
      status: 'error',
      message: data.message
    })

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari promo->getPromo', error)
    let msg = 'Error ambil data promo'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    showNotif({
      status: 'error',
      message: msg
    })
    throw error
  } finally {
    loading.getPromo = false
  }
}
