import axios from '../plugins/axios'
import { ref, reactive } from 'vue'
import { showNotif } from '@/core/utility'
import { analytics, getTokenAfterLogin } from './firebase-messaging'
import { user, token } from '@/core/auth'

export const listAnggotaKeluarga = ref(null)
export const listHubunganKeluarga = ref(null)
export const anggotaKeluargaDetail = ref(null)
export const loading = reactive({
  getAnggotaKeluarga: false,
  getAnggotaKeluargaDetail: false,
  getHubunganKeluarga: false,
  submitAnggotaKeluarga: false,
  deleteAnggotaKeluarga: false,
  updateAnggotaKeluarga: false
})

export const getHubunganKeluarga = async () => {
  try {
    loading.getHubunganKeluarga = true
    const { data } = await axios.get('app/hubungan_keluarga')

    if (data.success === true) {
      listHubunganKeluarga.value = data.data
      return {
        status: true,
        message: 'Sukses'
      }
    }

    showNotif({
      status: 'error',
      message: data.message
    })

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari anggotaKeluarga->getHubunganKeluarga', error)
    let msg = 'Error ambil data hubungan keluarga'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    showNotif({
      status: 'error',
      message: msg
    })
    throw error
  } finally {
    loading.getHubunganKeluarga = false
  }
}

export const getAnggotaKeluarga = async () => {
  try {
    loading.getAnggotaKeluarga = true
    const { data } = await axios.get('app/list_anggota_v2', {
      params: {
        id_user: user.value.id
      }
    })

    if (data.success === true) {
      listAnggotaKeluarga.value = data.data
      return {
        status: true,
        message: 'Sukses'
      }
    }

    showNotif({
      status: 'error',
      message: data.message
    })

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari anggotaKeluarga->getAnggotaKeluarga', error)
    let msg = 'Error ambil data anggota keluarga'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    showNotif({
      status: 'error',
      message: msg
    })
    throw error
  } finally {
    loading.getAnggotaKeluarga = false
  }
}

export const getAnggotaKeluargaDetail = async (payload) => {
  try {
    loading.getAnggotaKeluargaDetail = true
    const { data } = await axios.get('app/list_anggota_detail_v2', {
      params: payload
    })

    if (data.success === true) {
      anggotaKeluargaDetail.value = data.data[0]
      return {
        status: true,
        message: 'Sukses'
      }
    }

    showNotif({
      status: 'error',
      message: data.message
    })

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari anggotaKeluarga->getAnggotaKeluargaDetail', error)
    let msg = 'Error ambil data anggota keluarga detail'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    showNotif({
      status: 'error',
      message: msg
    })
    throw error
  } finally {
    loading.getAnggotaKeluargaDetail = false
  }
}

export const submitAnggotaKeluarga = async (payload) => {
  try {
    loading.submitAnggotaKeluarga = true
    const { data } = await axios.post('app/registrasi_anggota_v2', payload, {
      headers: {
        id_user: user.value.id,
        token_api: token.value
      }
    })

    analytics.logEvent('add_anggota')
    if (data.success === true) {
      getTokenAfterLogin()
      return {
        status: true,
        message: 'Sukses'
      }
    }

    showNotif({
      status: 'error',
      message: data.message
    })

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari anggotaKeluarga->submitAnggotaKeluarga', error)
    let msg = 'Error kirim data anggota keluarga'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    showNotif({
      status: 'error',
      message: msg
    })
    throw error
  } finally {
    loading.submitAnggotaKeluarga = false
  }
}

export const updateAnggotaKeluarga = async (payload) => {
  try {
    loading.updateAnggotaKeluarga = true
    const { data } = await axios.post('app/update_anggota_v2', payload, {
      headers: {
        id_user: user.value.id,
        token_api: token.value
      }
    })

    analytics.logEvent('update_anggota')
    if (data.success === true) {
      getTokenAfterLogin()
      return {
        status: true,
        message: 'Sukses'
      }
    }

    showNotif({
      status: 'error',
      message: data.message
    })

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari anggotaKeluarga->updateAnggotaKeluarga', error)
    let msg = 'Error update data anggota keluarga'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    showNotif({
      status: 'error',
      message: msg
    })
    throw error
  } finally {
    loading.updateAnggotaKeluarga = false
  }
}

export const deleteAnggotaKeluarga = async (payload) => {
  try {
    loading.deleteAnggotaKeluarga = true
    const { data } = await axios.post('app/hapus_anggota_v2', payload, {
      headers: {
        id_user: user.value.id,
        token_api: token.value
      }
    })

    analytics.logEvent('delete_anggota')

    if (data.success === true) {
      return {
        status: true,
        message: 'Sukses'
      }
    }

    showNotif({
      status: 'error',
      message: data.message
    })

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari anggotaKeluarga->deleteAnggotaKeluarga', error)
    let msg = 'Error hapus anggota keluarga'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    showNotif({
      status: 'error',
      message: msg
    })
    throw error
  } finally {
    loading.deleteAnggotaKeluarga = false
  }
}
