<template>
  <div class="container px-0">
    <app-bar
      :noDivider="true"
      class="sub narrow"
      :title="voucherDetailExternal?.kode_voucher"
      :previousLink="{ name: 'Voucher' }"
    />
    <div class="col-12 col-md-6 mx-auto container-detail-voucher">
      <iframe :src="voucherDetailExternal?.info_detail" class="w-100 h-100 d-block"></iframe>
    </div>
  </div>
</template>

<script>
// mandatory
import AppBar from '@/components/AppBar'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

// data
import { voucherDetailExternal } from '@/core/voucher'

export default {
  components: {
    AppBar
  },
  setup () {
    const router = useRouter()

    onMounted(() => {
      if (!voucherDetailExternal.value) {
        return router.push({
          name: 'Voucher'
        })
      }
    })

    return {
      voucherDetailExternal
    }
  }
}
</script>

<style lang="scss" scoped>
.container-detail-voucher {
  margin-top: 51px;
  height: calc(100vh - 51px);

  @media (min-width: 521px) {
    margin-top: 0px;
    height: calc(100vh - 31px);
  }

  @media (min-width: 768px) {
    margin-top: 0px;
    height: calc(100vh - 103px);
  }

  @media (min-width: 992px) {
    margin-top: 0px;
    height: calc(100vh - 111px);
  }
}
</style>
