<template>
    <div id="main-page">
        <div class="container">
          <app-bar
            title="Semua Promo"
            previous-link="/" />
          <Loading v-if="loading.getPromo" />
          <div v-else-if="listPromo.length" class="row">
            <div v-for="promo in listPromo" :key="promo" class="col-6 col-md-4 pt-2 pb-2">
              <div class="card border-0 shadow" @click="handleOnClick(promo)">
                <div class="card-body p-0">
                  <div class="d-flex flex-column">
                    <div class="border-radius-10">
                      <img class="icon-vouchers" :src="promo.banner">
                    </div>
                    <div class="pt-2 pb-3 px-3">
                      <h5 class="card-title fw-700">
                        {{ promo.nama }}
                      </h5>

                      <div v-if="promo.status !== 'VOUCHER'" class="mt-3">
                        <div class="discount-price color-primary-400">
                          Rp. {{ parseInt(promo.harga).toLocaleString('id-ID') }}
                        </div>
                        <div class="desc-voucher fs-12 lh-22 mb-md-3">
                          Harga Asli Rp. {{ parseInt(promo.harga_coret).toLocaleString('id-ID') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="not-found d-flex flex-column text-center pb-5 pt-5">
            <div class="bd-highlight">
                <img src="@/assets/icon/not-found.png" alt="">
            </div>
            <div class="bd-highlight">
                <p class="text-title-active fm-poppins fst-normal fw-600 fs-24 lh-36">Tidak Ada Hasil</p>
            </div>
            <div class="bd-highlight">
                <p class="text-body-light fm-nunito fst-normal fw-400 fs-16 lh-21">Sepertinya kami tidak dapat menemukan hasil apa pun berdasarkan penelusuran Anda.</p>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import { nextTick, onMounted } from 'vue' // computed
import { title } from '@/core/page'
import { analytics } from '@/core/firebase-messaging'
import { selectedPromo } from '@/core/subDivisions'
import Loading from '@/components/utility/Loading'
import { useRouter } from 'vue-router'
import { getPromo, listPromo, loading } from '@/core/promo'
import { showNotif } from '@/core/utility'
import { token } from '@/core/auth'

export default {
  components: {
    AppBar, Loading
  },
  setup () {
    const router = useRouter()

    onMounted(async () => {
      await getPromo()

      nextTick(() => {
        title.value = 'Semua Promo'
      })
    })

    const handleOnClick = (promo) => {
      analytics.logEvent('promo_clicked')

      // jika promo tidak aktif, tampilkan notif
      if (promo.status_aktif === 0 || promo.status_aktif === '0') {
        return showNotif({
          status: 'warning',
          message: 'Promo Belum dapat digunakan'
        })
      } else if (!token.value) { // jika blm login, login dulu
        analytics.logEvent('need_to_login_first')
        router.push({ name: 'Login' })
      } else {
        selectedPromo.value = promo
        router.push({
          name: 'SelectBranch',
          query: {
            redirectTo: `/layanan/promo/sub/${promo.id_dt}`,
            type: 'promo'
          }
        })
      }
    }

    return {
      loading,
      handleOnClick,
      listPromo
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-vouchers {
  width: 100%;
  // height: 154px;
}

.card {
  border-radius: 10px;

  .card-title {
    @media (max-width: 580px) {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 2px;
    }
  }

  .desc-voucher {
    font-family: 'Inter', sans-serif;
    text-decoration: line-through;

    @media (max-width: 580px) {
      margin-bottom: 8px;
    }
  }

  img {
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .button-group {
    .btn {
      width: 100%;

      .btn-title {
        @media (max-width: 580px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}

.title-content-package {
  font-size: 22px;
  color: #1A202C;

  @media (max-width: 580px) {
    font-size: 16px;
  }
}
</style>
