<template>
  <div id="main-page">
    <div class="container">
      <app-bar
        :isBgPrimary="isMobile"
        :noDivider="true"
        title="Home Service"
        previous-link="/home-service/pilih-cabang" />

      <div class="row pt-5 pt-md-0 pb-3" :class="{ 'bg-primary text-white': isMobile }">
        <h6 class="fw-700 fs-18 lh-28 mb-1">{{ selectedBranch.nama }}</h6>
        <span
          class="small fw-400 fs-12 lh-22 mb-1"
          :class="{ 'color-primary-50': isMobile }">{{ selectedBranch.alamat }}</span>
        <div class="d-flex align-items-center fw-400 fs-14 lh-24 gap-1">
          <img
            src="@/assets/icon/routing.svg"
            alt="Icon Routing">
          <span>{{ parseFloat(selectedBranch.distance).toFixed(2) }} km</span>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8">
          <Loading v-if="loading.homeService" />
          <Empty v-else-if="listHomeService.length === 0" :message="'Saat ini belum ada Best Seller untuk ditampilkan.'"/>
          <div v-else v-for="homeService in listHomeService" :key="homeService.id">
            <div v-for="detail in homeService.detail" :key="detail.kode">
              <div class="card card-service border-0">
                <div class="card-body d-flex align-items-center justify-content-between">
                  <div class="d-flex flex-row align-items-center gap-2">
                    <img
                      src="@/assets/services/blood-test.svg"
                      alt="Icon">
                    <div>
                      <div class="d-flex flex-column">
                        <div class="service-title fw-700 fs-14 lh-16 color-neutral-900 mb-1">
                          {{ detail.nama }}
                        </div>
                        <div class="service-price fw-400 fs-12 lh-14 color-secondary-500">
                          Rp. {{ detail.harga?.toLocaleString('id-ID') }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      class="icon-wrapper"
                      :class="{ 'active': cart.arr_paket.find(_cart => _cart.id_dt === detail.id_dt) }"
                      @click="addToCart(detail, homeService.group)"
                    >
                      <img
                        src="@/assets/icon/icon-plus.svg"
                        class="add"
                        alt="Ikon tambah">
                      <img
                        src="@/assets/icon/icon-close.svg"
                        class="remove"
                        alt="Ikon hapus">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- card summary -->
        <div class="col-md-4">
          <div class="booking-summary">
            <div class="card card-summary-order border-0">
              <div class="row justify-content-center pt-2">
                <div class="tab-line bg-neutral-300" />
              </div>
              <div
                class="card-body pb-1 d-flex flex-md-column"
                :class="{
                  'flex-row justify-content-between': !isSummaryForMobileShown,
                  'flex-column': isSummaryForMobileShown
                }">
                <div class="d-flex flex-row align-items-center justify-content-between py-md-2">
                  <div class="d-flex align-items-center gap-2 mb-3 mb-md-0">
                    <div class="icon-wrapper">
                      <img
                        src="@/assets/icon/cart.png"
                        alt="Icon"
                        class="summary-icon"
                      >
                    </div>
                    <div>
                      <p class="ringkasan-pemesanan fw-400 fs-10 lh-12 color-neutral-600 mb-1">
                        Ringkasan Pemesanan
                      </p>
                      <strong class="total-service fw-700 fs-12 lh-14 color-neutral-900">
                        {{ cart.arr_paket?.length }} layanan terpilih
                      </strong>
                    </div>
                  </div>
                  <div
                    :class="{
                      'd-none d-md-block': !isSummaryForMobileShown
                    }">
                    <button class="btn btn-delete bg-gray-6" @click="clearCart">
                      <p class="fst-normal mb-0 text-gray-3 fw-600 fs-14 fm-nunito">Hapus Semua</p>
                    </button>
                  </div>
                </div>

                <!-- list cart -->
                <section
                  :class="{
                    'd-none d-md-block': !isSummaryForMobileShown
                  }">
                  <div
                    v-for="(summary) in Object.keys(summaries)" :key="summary" class="group d-flex flex-column">
                    <div v-for="detail in summaries[summary]" :key="detail" class="py-2">
                      <p class="fst-normal text-gray-3 fs-14 mb-0 fm-nunito">
                        <button class="btn" @click="removeFromCart(detail)">
                          <i class="text-danger ri-indeterminate-circle-line"></i>
                        </button>
                        {{ detail.nama }}
                      </p>
                    </div>
                  </div>
                </section>

                <div>
                  <div
                    class="divider pt-3"
                    :class="{
                      'd-none d-md-block': !isSummaryForMobileShown
                    }">
                    <hr class="border bg-gray-6 my-0">
                  </div>
                  <div
                    class="py-2"
                    :class="{
                      'd-none d-md-block': !isSummaryForMobileShown
                    }">
                    <div class="d-flex flex-row align-items-center justify-content-between py-2">
                      <div>
                        <p class="text-gray-3 fs-14 lh-19 mb-0">
                          Total Layanan
                        </p>
                      </div>
                      <div>
                        <p class="text-gray-1 fs-14 fw-600 mb-0">
                          {{ cart.arr_paket?.length }} Layanan
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="pb-3 d-flex flex-row-reverse align-items-center">
                    <router-link
                      class="btn btn-payment d-md-none py-1"
                      :class="{
                        [`btn-${isSummaryForMobileShown ? 'light' : 'orange'}`]: true
                      }"
                      :to="{
                        query: { showSummary: !isSummaryForMobileShown }
                      }">
                      <i v-if="isSummaryForMobileShown" class="ri-arrow-down-s-line"></i>
                      <span
                        v-else
                        class="btn-title text-white fw-600 fs-12 lh-18 p-0 m-0">
                        Lanjutkan
                      </span>
                    </router-link>
                    <button
                      class="btn btn-primary w-100 py-2 flex-grow-1"
                      :class="{
                        'd-none d-md-block': !isSummaryForMobileShown
                      }"
                      @click="$router.push({
                        name: 'HomeServiceCheckout'
                      })"
                    >
                      Lanjutkan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import Loading from '@/components/utility/Loading'
import Empty from '@/components/utility/Empty'
import { nextTick, onMounted, ref, computed } from 'vue'
import { title } from '@/core/page'
import { selectedBranch, getHomeService, listHomeService, addToCart, cart, getCart, clearCart, getBestSeller, bestSellers, loading, removeFromCart } from '@/core/homeService'
import { useRoute } from 'vue-router'

export default {
  components: {
    AppBar,
    Loading,
    Empty
  },
  setup () {
    const keyword = ref('')
    const route = useRoute()
    const tempCart = ref([])
    const isSummaryForMobileShown = computed(() => route.query.showSummary === 'true')
    const isMobile = computed(() => window.screen.width < 576)

    const prepareSearch = () => {
      getHomeService(selectedBranch.value.id, keyword.value)
    }

    const addBestSellerToCart = (kode) => {
      let hs = null
      let group = ''
      // loop semua paket nya
      for (let i = 0; i < listHomeService.value.length; i++) {
        // const result = listHomeService.value[i].find(homeService => homeService.kode === kode)
        for (let j = 0; j < Object.values(listHomeService.value[i].detail).length; j++) {
          if (Object.values(listHomeService.value[i].detail)[j].kode === kode) {
            hs = Object.values(listHomeService.value[i].detail)[j]
            group = listHomeService.value[i].group
            break
          }
        }

        if (hs !== null) {
          // add to cart
          addToCart(hs, group)
          break
        }
      }
    }

    const summaries = computed(() => {
      const temp = []
      cart.value.arr_paket.map((val) => {
        if (!temp[val.group]) {
          temp[val.group] = [val]
        } else {
          temp[val.group].push(val)
        }
      })
      return temp
    })

    onMounted(() => {
      getBestSeller()
      getHomeService(selectedBranch.value.id, keyword.value)
      getCart()
      nextTick(() => {
        title.value = 'Pilih Cabang'
      })
    })

    return {
      loading,
      selectedBranch,
      keyword,
      listHomeService,
      prepareSearch,
      addToCart,
      cart,
      isSummaryForMobileShown,
      clearCart,
      summaries,
      isMobile,
      bestSellers,
      removeFromCart,
      addBestSellerToCart,
      tempCart
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables';

.card-service {
  .card-body {
    padding: 12px 0px;
    box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
    width: 100%;

    .service-icon img {
      width: 42px;
      height: 42px;

      &:not(.loaded) {
        background-color: #F2F2F2;
      }
    }

    .service-title {
      max-width: 250px;
    }
  }
}

.booking-summary {
  @media (max-width: 520px) {
    position: fixed;
    bottom: 0rem;
    left: 0;
    right: 0;
    box-shadow: 0rem 0rem 1.5rem rgba(0, 0, 0, 0.2);
    transition: all .3s ease-in-out;
    max-height: 93vh;

    section {
      max-height: 61vh;
      overflow-y: auto;
    }
  }

  .card-summary-order {
    border-radius: 10px !important;
    box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.05);

    .tab-line {
      width: 48px;
      height: 4px;
      border-radius: 14px;
    }

    .ringkasan-pemesanan {
      font-size: 10px;
      line-height: 12px;

      @media (max-width: 520px) {
        color: rgb(179, 179, 179);
      }
    }

    .total-service {
      font-size: 12px;
      line-height: 14px;
    }

    .btn-payment {
      box-shadow: none;
    }
  }
}

.remove {
  display: none;
}

.active {
  .add {
    display: none;
  }

  .remove {
    display: block;
  }
}
</style>
