import axios from '../plugins/axios'
import { ref, reactive } from 'vue'
import { showNotif } from '@/core/utility'
import { user, token } from '@/core/auth'

export const voucherDetail = ref(null)
export const voucherDetailExternal = ref(null)
export const vouchers = ref([])
export const loading = reactive({
  getVouchers: false,
  getVoucherDetail: false,
  claimVoucher: false
})

export const getVouchers = async () => {
  try {
    loading.getVouchers = true
    const { data } = await axios.get('member/voucherku', {
      headers: {
        id_user: user.value.id,
        token: token.value
      }
    })

    if (data.success === true) {
      vouchers.value = data.data
      return {
        status: true,
        message: 'Sukses'
      }
    }

    showNotif({
      status: 'error',
      message: data.message
    })

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari voucher->getVouchers', error)
    let msg = 'Error ambil list voucher'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    showNotif({
      status: 'error',
      message: msg
    })
    throw error
  } finally {
    loading.getVouchers = false
  }
}

export const getVoucherDetail = async (payload) => {
  try {
    loading.getVoucherDetail = true
    const { data } = await axios.get('member/qr_kode', {
      params: payload,
      headers: {
        id_user: user.value.id,
        token: token.value
      }
    })

    if (data.success === true) {
      voucherDetail.value = data.data
      return {
        status: true,
        message: 'Sukses'
      }
    }

    showNotif({
      status: 'error',
      message: data.message
    })

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari voucher->getVoucherDetail', error)
    let msg = 'Error ambil detail voucher'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    showNotif({
      status: 'error',
      message: msg
    })
    throw error
  } finally {
    loading.getVoucherDetail = false
  }
}

export const claimVoucher = async (payload) => {
  try {
    loading.claimVoucher = true
    const { data } = await axios.post('member/klaim_voucher', payload, {
      headers: {
        id_user: user.value.id,
        token: token.value
      }
    })

    if (data.success === true) {
      return {
        status: true,
        message: data.message
      }
    }

    showNotif({
      status: 'error',
      message: data.message
    })

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari voucher->claimVoucher', error)
    let msg = 'Error klaim voucher'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    showNotif({
      status: 'error',
      message: msg
    })
    throw error
  } finally {
    loading.claimVoucher = false
  }
}
