import { user, token } from '@/core/auth'
import { ref, reactive } from 'vue'
import { showNotif } from '@/core/utility'
import axios from '../plugins/axios'

export const partnerships = ref([])
export const loading = reactive({
  getPartnerships: false
})

export const getPartnerships = async () => {
  try {
    loading.getPartnerships = true
    const { data } = await axios.get('app/list_voucher_eks', {
      headers: {
        id_user: user.value.id,
        token: token.value
      }
    })

    if (data.success === true) {
      partnerships.value = data.data
      return {
        status: true,
        message: 'Sukses'
      }
    }

    showNotif({
      status: 'error',
      message: data.message
    })

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari partnership->getPartnerships', error)
    let msg = 'Error ambil list partnership'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    showNotif({
      status: 'error',
      message: msg
    })
    throw error
  } finally {
    loading.getPartnerships = false
  }
}
