<template>
  <div id="main-page">
    <div class="container">
      <app-bar
        :title="detailService && detailService.nama || 'Loading..'"
        :previousLink="generatePreviousLink()"
      />

      <!-- <router-link
        :to="{
          name: 'SelectBranch',
          query: {
            redirectTo: $route.path
          }
        }"
        class="address-box d-md-none border p-2 pe-4 mb-4 rounded d-flex flex-column">
        <strong>{{ selectedBranch.nama }}</strong>

        <small class="text-muted">{{ selectedBranch.alamat }}</small>

        <i class="ri ri-arrow-right-s-line"></i>
      </router-link> -->

      <div
        class="text-center"
        v-if="loading.detailService && !detailService">
        Loading...
      </div>
      <div v-else class="row">
        <!-- Icon -->
        <div class="col-md-2 px-3">
          <div class="content-group">
            <div class="d-flex flex-column align-items-center justify-content-center text-center">
              <img class="product-img" :src="detailService.icon">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <div class="fw-700 fs-18 lh-28 color-neutral-900 mt-md-3 mb-1">
                  {{ detailService.nama }}
                </div>
                <div class="service-price pt-0 pt-md-3">
                  <p v-if="detailService.harga" class="fw-400 fs-14 lh-24 color-secondary-500 mb-0">
                    Rp. {{ detailService.harga?.toLocaleString('id-ID') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Description -->
        <div class="col-md-6 px-3">
          <div v-for="desc in detailService.deskripsi" :key="desc.judul" class="desc-group mt-4 mt-md-0">
            <p class="fw-700 fs-14 lh-24 color-neutral-900 mb-1">
              {{ desc.judul }}
            </p>
            <p v-if="!desc.isi" class="fw-400 fs-14 lh-24 color-neutral-600" style="font-style: italic">
              Tidak ada deskripsi
            </p>
            <p v-else class="fw-400 fs-14 lh-24 color-neutral-600" style="text-align: justify">
              {{ desc.isi }}
            </p>
          </div>

          <!-- Paket Layanan Pranikah -->
          <div class="list-layanan mt-4 mt-md-0 pb-md-0 mb-md-0">
            <div
              v-if="detailService.pemeriksaan"
              class="package-service d-flex flex-column"
            >
              <span class="fw-700 fs-14 color-neutral-900 mb-1">
                Paket Layanan
              </span>

              <!-- List Layanan -->
              <div
                v-for="layanan in detailService.pemeriksaan"
                :key="layanan.kode"
                class="package-service-item d-flex align-item-center justify-content-between"
                role="button"
                @click="openDetail(layanan.id_dt)"
              >
                <span class="fw-600 fs-14 lh-24 color-neutral-700">{{ layanan.nama }}</span>
                <div class="icon-wrapper">
                  <img
                    src="@/assets/icon/arrow-right.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <!-- Bagian Notif -->
            <div v-if="displayNotif" ref="notifElement" class="notification bg-info-50 d-flex align-items-center gap-3">
              <div class="icon-wrapper">
                <img
                  src="@/assets/icon/topup-wallet.svg"
                  alt=""
                >
              </div>
              <span class="fw-400 fs-12 color-info-500">
                {{ messageNotif }}
              </span>
            </div>
          </div>

          <!-- Add To Cart Desktop Screen -->
          <div class="d-none d-md-block pt-3 mb-4">
            <button class="btn btn-add-to-cart text-white w-100 pt-2 pb-2" :class="{ 'btn-orange': itemIndex < 0, 'btn-disable': itemIndex > -1}" @click="prepareToCart()">
              {{ (itemIndex > -1) ? 'Hapus Dari Keranjang' : 'Tambahkan' }}
            </button>
          </div>
        </div>

        <!-- Cart -->
        <div class="col-md-4">
          <div class="booking-summary">
            <div class="card card-summary-order border-0">
              <div class="row justify-content-center pt-2">
                <div class="tab-line bg-neutral-300 mb-3" />

                <!-- Add To Cart Mobile Screen -->
                <div class="d-md-none" style="padding: 0px 30px;">
                  <button
                    class="btn text-white w-100 py-2"
                    :class="{ 'btn-orange': itemIndex < 0, 'btn-disable': itemIndex > -1}"
                    @click="prepareToCart()">
                    {{ (itemIndex > -1) ? 'Hapus Dari Keranjang' : 'Tambahkan' }}
                  </button>
                </div>
              </div>
              <div
                class="card-body pb-1 d-flex flex-md-column"
                :class="{
                  'flex-row justify-content-between': !isSummaryForMobileShown,
                  'flex-column': isSummaryForMobileShown
                }">
                <!-- Summary -->
                <div class="d-flex flex-row align-items-start justify-content-between py-md-2">
                  <div
                    class="d-flex align-items-center gap-2"
                    :class="{
                      'mb-3': isSummaryForMobileShown
                    }">
                    <div class="icon-wrapper">
                      <img
                        src="@/assets/icon/cart.png"
                        alt=""
                        class="summary-icon"
                      >
                    </div>
                    <div>
                      <p class="ringkasan-pemesanan fw-400 fs-10 lh-12 color-neutral-600 mb-1">
                        Ringkasan Pemesanan
                      </p>
                      <strong class="total-service fw-700 fs-12 lh-14 color-neutral-900">
                        {{ tempCart?.length }} layanan terpilih
                      </strong>
                    </div>
                  </div>
                  <div
                    :class="{
                      'd-none d-md-block': !isSummaryForMobileShown
                    }">
                    <button class="btn btn-delete bg-gray-6" @click="prepareClearCart">
                      <p class="fst-normal mb-0 text-gray-3 fw-600 fs-14 fm-nunito">Hapus Semua</p>
                    </button>
                  </div>
                </div>
                <section
                  :class="{
                    'd-none d-md-block': !isSummaryForMobileShown
                  }">
                  <div
                    v-for="(summary) in Object.keys(summaries)" :key="summary" class="group d-flex flex-column">
                    <div class="py-2">
                      <p class="fst-normal text-gray-1 fw-600 fs-14 mb-0 fm-poppins">
                        {{ summary }}
                      </p>
                    </div>
                    <div v-for="detail in summaries[summary]" :key="detail" class="py-2">
                      <p class="fst-normal text-gray-3 fs-14 mb-0 fm-nunito">
                        <button class="btn" @click="removeServiceFromCart(detail)">
                          <i class="text-danger ri-indeterminate-circle-line"></i>
                        </button>
                        {{ detail.nama }}
                      </p>
                    </div>
                  </div>
                </section>
                <div>
                  <div
                    class="divider pt-3"
                    :class="{
                      'd-none d-md-block': !isSummaryForMobileShown
                    }">
                    <hr class="border bg-gray-6 my-0">
                  </div>
                  <div
                    class="py-2"
                    :class="{
                      'd-none d-md-block': !isSummaryForMobileShown
                    }">
                    <div class="d-flex flex-row align-items-center justify-content-between py-2">
                      <div>
                        <p class="text-gray-3 fs-14 lh-19 mb-0">
                          Total Layanan
                        </p>
                      </div>
                      <div>
                        <p class="text-gray-1 fs-14 fw-600 mb-0">
                          {{ tempCart?.length }} Layanan
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="pb-3 d-flex flex-row-reverse">
                    <router-link
                      class="btn py-2 d-md-none ms-2"
                      :class="{
                        [`btn-${isSummaryForMobileShown ? 'light' : 'primary'}`]: true
                      }"
                      :to="{
                        query: { showSummary: !isSummaryForMobileShown }
                      }">
                      <i
                        :class="{
                          [`ri-arrow-${isSummaryForMobileShown ? 'down' : 'up'}-s-line`]: true
                        }"
                      />
                    </router-link>
                    <button
                      class="btn btn-primary w-100 py-2 flex-grow-1"
                      :class="{
                        'd-none d-md-block': !isSummaryForMobileShown
                      }"
                      @click="prepareToCheckout">
                      Lanjutkan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="modalSubService" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-body" v-if="subService && !loading.detailService">
            <div class="d-flex flex-column align-items-center justify-content-center text-center">
              <img v-if="subService.icon" class="product-img" :src="subService.icon">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <span class="fw-700 fs-18 lh-28 color-neutral-900 mt-md-3 mb-1">
                  {{ subService.nama }}
                </span>
              </div>
            </div>
            <div v-for="desc in subService.deskripsi" :key="desc.judul" class="desc-group mt-4 mt-md-0">
              <p class="fw-700 fs-14 lh-24 color-neutral-900 mb-1">
                {{ desc.judul }}
              </p>
              <p v-if="!desc.isi" class="fw-400 fs-14 lh-24 color-neutral-600" style="font-style: italic">
                Tidak ada deskripsi
              </p>
              <p v-else class="fw-400 fs-14 lh-24 color-neutral-600" style="text-align: justify">
                {{ desc.isi }}
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary w-100 py-2" data-bs-dismiss="modal">Tutup</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap'
import { getSubServiceDetail, getServiceDetail, detailService, loading, activeService } from '@/core/service'
import { nextTick, onMounted, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import AppBar from '@/components/AppBar.vue'
import { title } from '@/core/page'
import { selectedBranch } from '@/core/subDivisions'
import { addToCart, cart, getCart, removeFromCart, clearCart } from '@/core/cart'
import { analytics } from '@/core/firebase-messaging'
import { showNotif } from '@/core/utility'

export default {
  components: {
    AppBar
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const displayNotif = ref(false)
    const messageNotif = ref('')
    const notifElement = ref(null)
    const tempCart = ref([])
    const isSummaryForMobileShown = computed(() => route.query.showSummary === 'true')
    const itemIndex = computed(() => {
      return cart.value.arr_paket.findIndex(_item => _item.kode === detailService.value.kode)
    })
    const subService = ref(null)

    const generatePreviousLink = () => {
      if (route.params.id_layanan === 'promo') {
        return {
          name: 'Home'
        }
      }

      return {
        name: 'SubService',
        params: route.params
      }
    }

    const summaries = computed(() => {
      const temp = []
      tempCart.value.map((cart) => {
        if (!temp[cart.nama_group]) {
          temp[cart.nama_group] = [cart]
        } else {
          temp[cart.nama_group].push(cart)
        }
      })
      return temp
    })

    const updateCart = async () => {
      // Kosongkan dulu tempcart
      tempCart.value = []

      // Ambil data cart dari online
      await getCart()
      for (let i = 0; i < cart.value.arr_paket.length; i++) {
        if (cart.value.arr_paket[i].id_group !== null) {
          tempCart.value.push(cart.value.arr_paket[i])
        }
      }
    }

    const removeServiceFromCart = async (item) => {
      const itemIndex = tempCart.value.findIndex(_item => _item.id_dt === item.id_dt)
      await removeFromCart(tempCart.value[itemIndex])

      // Get data terbaru setelah proses
      await updateCart()
    }

    const prepareClearCart = async () => {
      await clearCart(false)
      // jika promo, ketika hapus cart balik ke home lagi aja, biar pilih promo lagi
      if (route.params.id_layanan === 'promo') {
        return router.push({
          name: 'Home'
        })
      }
      await updateCart()
    }

    const updateNotif = (status, message) => {
      displayNotif.value = status
      messageNotif.value = message
      nextTick(() => {
        notifElement.value.scrollIntoView({ behavior: 'smooth' })
      })
    }

    const prepareToCart = async () => {
      // cek cabang
      if (!selectedBranch.value) {
        // jika cabang kosong maka di redirect pilih cabang
        return router.push({
          name: 'SelectBranch',
          query: {
            redirectTo: route.path
          }
        })
      } else {
        if (itemIndex.value > -1) {
          const result = await removeFromCart(cart.value.arr_paket[itemIndex.value])
          if (result.status) {
            // jika promo, ketika hapus cart balik ke home lagi aja, biar pilih promo lagi
            if (route.params.id_layanan === 'promo') {
              return router.push({
                name: 'Home'
              })
            }

            updateNotif(true, 'Berhasil dihapus dari keranjang')
          }
        } else {
          const result = await addToCart({
            id_dt: detailService.value.id_dt,
            kode: detailService.value.kode,
            nama: detailService.value.nama,
            harga: detailService.value.harga,
            group: activeService.value?.nama || 'Covid-19'
          })

          if (result.status) {
            updateNotif(true, 'Berhasil ditambahkan ke keranjang')
          } else {
            let message = result.message
            if (message === 'login') {
              message = 'Silahkan login terlebih dahulu'
              router.push({
                name: 'Login'
              })
            }
            // Trigger toast
            showNotif({
              status: 'error',
              message: message
            })
          }
        }
        await getCart()
      }

      await updateCart()
    }

    const openDetail = async (id_dt) => {
      subService.value = await getSubServiceDetail({
        cabang: selectedBranch.value.id,
        idDt: id_dt
      })

      const modal = new Modal(
        document.querySelector('#modalSubService'), {
          backdrop: 'static'
        }
      )
      modal.show()
    }

    const prepareToCheckout = () => {
      if (tempCart.value.length !== 0) {
        // Arahkan ke halaman selanjutnya
        router.push({
          name: window.innerWidth < 580 ? 'Keranjang' : 'Checkout'
        })
      }
    }

    onMounted(async () => {
      await getServiceDetail({
        cabang: selectedBranch.value.id,
        idDt: route.params.id_dt
      })
      await getCart()

      nextTick(() => {
        title.value = (detailService.value && detailService.value.nama) || 'Tidak Ditemukan'
      })

      // tracking event view_item
      try {
        const payload = {
          items: [{
            item_id: detailService.value.kode,
            item_name: detailService.value.nama,
            price: parseFloat(detailService.value.harga),
            currency: 'IDR',
            item_category: activeService.value?.nama,
            item_list_name: `${activeService.value?.nama} cabang ${selectedBranch.value?.nama}`,
            item_list_id: `${activeService.value?.id_group}_cabang_${selectedBranch.value?.id}`,
            quantity: 1
          }],
          value: parseFloat(detailService.value.harga),
          currency: 'IDR'
        }

        analytics.logEvent('view_item', payload)
      } catch (error) {
        console.error(error)
      }

      await updateCart()
    })

    return {
      loading,
      detailService,
      activeService,
      selectedBranch,
      cart,
      displayNotif,
      messageNotif,
      itemIndex,
      notifElement,
      subService,
      isSummaryForMobileShown,
      tempCart,
      summaries,
      prepareToCart,
      removeFromCart,
      openDetail,
      prepareClearCart,
      removeServiceFromCart,
      generatePreviousLink,
      prepareToCheckout
    }
  }
}
</script>

<style lang="scss" scoped>
.product-img {
  width: 10rem;
  height: auto;
  margin-bottom: 16px;

  @media screen and (max-width: 540px) {
    width: 84px;
  }
}

.btn-buy {
    width: 35%;
    border-radius: 10px !important;
}

.btn-disable {
  background: #CBD5E0;
}

.btn:focus {
  box-shadow: none;
}

.btn-add-to-cart:hover {
  background-color: #de7e2a;
  border-color: #de7e2a;
}

.package-service {
  &-item {
    padding: 8px;
    box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;
  }
}

.booking-summary {
  @media (max-width: 520px) {
    position: fixed;
    bottom: 0rem;
    left: 0;
    right: 0;
    box-shadow: 0rem 0rem 1.5rem rgba(0, 0, 0, 0.2);
    transition: all .3s ease-in-out;
    max-height: 93vh;

    section {
      max-height: 61vh;
      overflow-y: auto;
    }
  }

  .card-summary-order {
    border-radius: 10px !important;
    box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.05);

    .tab-line {
      width: 48px;
      height: 4px;
      border-radius: 14px;
    }

    .ringkasan-pemesanan {
      font-size: 10px;
      line-height: 12px;

      @media (max-width: 520px) {
        color: rgb(179, 179, 179);
      }
    }

    .total-service {
      font-size: 12px;
      line-height: 14px;
    }

    .btn-payment {
      box-shadow: none;
    }
  }
}

.bottom-bar {
  @media (max-width: 520px) {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: none;
  }
}

.notification {
  padding: 10px;
  border-radius: 12px;
  margin-top: 10px;
}

.list-layanan {
  margin-bottom: 150px;
}

.modal-footer {
  border-top: none;
  border-bottom: 20px;
}

.modal-dialog {
  margin: 0 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.modal-dialog-scrollable {
  height: 100%;
}

.modal-content {
  border: none;
  border-radius: 20px 20px 0px 0px;
  position: absolute;
  height: auto;
}

@media (max-width: 767px) {
  .modal-content {
    bottom: 0;
    height: 90%;
  }
}

@media (min-width: 768px) {
  .modal-content {
    top: 50%;
    transform: translateY(-50%);
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }
}
</style>
