<template>
  <app-bar
    title="Anggota Keluarga"
    :previousLink="{
      name: 'Profil'
    }"
    :noBackButtonOnDesktop="true"
    class="sub"
  />
  <div class="row justify-content-center">
    <div class="'col-md-4'">
      <div class="card card-patient border-0">
          <div class="card-body p-0 d-flex flex-column overflow-y-auto flex-column">
            <div class="patient-list py-md-2 p-0 pb-3">
              <div
                v-for="anggotaKeluarga in listAnggotaKeluarga"
                :key="anggotaKeluarga"
                class="patient-item d-flex align-items-center"
              >
                <div class="patient-wrapper d-flex flex-shrink-0 align-items-center justify-content-center bg-info-500 rounded-circle">
                  <span class="patient-value fw-400 fs-14 lh-18 text-white">
                    {{ getInitialName(anggotaKeluarga.anggota_nama) }}
                  </span>
                </div>
                <div class="value-wrapper d-flex flex-column">
                  <span class="value-wrapper-name fw-700 fs-14 lh-18 color-neutral-900">{{ anggotaKeluarga.anggota_nama }}</span>
                  <label class="value-wrapper-email fw-400 fs-12 lh-14 color-neutral-600">{{ anggotaKeluarga.nama_hubungan }} ({{ anggotaKeluarga.anggota_nowa }})</label>
                </div>
                <div class="btn-action d-flex flex-grow-1 justify-content-end gap-3">
                  <router-link
                    class="btn bg-warning color-neutral-600 align-items-center p-2"
                    :to="{ name: 'SettingEditAnggotaKeluarga', params: {pas_id: anggotaKeluarga.pas_id } }"
                  >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 22H3C2.59 22 2.25 21.66 2.25 21.25C2.25 20.84 2.59 20.5 3 20.5H21C21.41 20.5 21.75 20.84 21.75 21.25C21.75 21.66 21.41 22 21 22Z" fill="#ffffff"/>
                    <path d="M19.02 3.47991C17.08 1.53991 15.18 1.48991 13.19 3.47991L11.98 4.68991C11.88 4.78991 11.84 4.94991 11.88 5.08991C12.64 7.73991 14.76 9.85991 17.41 10.6199C17.45 10.6299 17.49 10.6399 17.53 10.6399C17.64 10.6399 17.74 10.5999 17.82 10.5199L19.02 9.30991C20.01 8.32991 20.49 7.37991 20.49 6.41991C20.5 5.42991 20.02 4.46991 19.02 3.47991Z" fill="#ffffff"/>
                    <path d="M15.61 11.53C15.32 11.39 15.04 11.25 14.77 11.09C14.55 10.96 14.34 10.82 14.13 10.67C13.96 10.56 13.76 10.4 13.57 10.24C13.55 10.23 13.48 10.17 13.4 10.09C13.07 9.81005 12.7 9.45005 12.37 9.05005C12.34 9.03005 12.29 8.96005 12.22 8.87005C12.12 8.75005 11.95 8.55005 11.8 8.32005C11.68 8.17005 11.54 7.95005 11.41 7.73005C11.25 7.46005 11.11 7.19005 10.97 6.91005C10.9488 6.86465 10.9283 6.81949 10.9085 6.77458C10.7609 6.44127 10.3262 6.34382 10.0685 6.60158L4.34001 12.33C4.21001 12.46 4.09001 12.71 4.06001 12.88L3.52001 16.71C3.42001 17.39 3.61001 18.03 4.03001 18.46C4.39001 18.81 4.89001 19 5.43001 19C5.55001 19 5.67001 18.99 5.79001 18.97L9.63001 18.43C9.81001 18.4 10.06 18.28 10.18 18.15L15.9013 12.4287C16.1609 12.1692 16.063 11.7237 15.7254 11.5797C15.6874 11.5634 15.6489 11.5469 15.61 11.53Z" fill="#ffffff"/>
                  </svg>
                  </router-link>
                  <button class="btn bg-danger-500 color-neutral-600 align-items-center p-2" @click="prepareDelete(anggotaKeluarga.pas_id)">
                    <img
                      src="@/assets/icon/trash-3.svg"
                      alt="Hapus"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-5 mb-0 mb-md-3 mb-xs-3">
              <div class="btn-wrapper py-3">
                <router-link
                  class="btn btn-primary rounded-pill w-100"
                  :to="{ name: 'SettingAddAnggotaKeluarga' }"
                >
                  <span class="fw-600 fs-14 lh-20">Tambah Keluarga</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { getAnggotaKeluarga, listAnggotaKeluarga, deleteAnggotaKeluarga } from '@/core/anggotaKeluarga'
import AppBar from '@/components/AppBar.vue'

export default {
  components: {
    AppBar
  },
  setup () {
    const loadingDeleteAnggota = ref(false)
    const errorMessageApi = ref('')

    const getInitialName = (fullname) => {
      if (fullname) {
        const totalWord = fullname.split(' ').length
        const fullName = fullname.split('')

        // cek jumlah kata
        if (totalWord === 1) {
          const initials = fullName.shift().charAt(0)
          return initials.toUpperCase()
        }

        const initials = fullname.split(' ')[0][0] + fullname.split(' ')[1][0]
        return initials.toUpperCase()
      }

      return ''
    }

    const prepareDelete = async (pasId) => {
      try {
        loadingDeleteAnggota.value = true

        // Reset pesan errornya dulu
        errorMessageApi.value = ''

        // prepare form data
        const fd = new FormData()

        // Append object ke formData
        fd.append('pas_id', pasId)

        const result = await deleteAnggotaKeluarga(fd)
        if (!result.status) {
          errorMessageApi.value = result.message
          return
        }
        getAnggotaKeluarga()
      } catch (error) {
        console.error('INI ERROR = ', error)
        errorMessageApi.value = error.response.data.message
      } finally {
        loadingDeleteAnggota.value = false
      }
    }

    onMounted(() => {
      getAnggotaKeluarga()
    })

    return {
      listAnggotaKeluarga,
      getInitialName,
      prepareDelete
    }
  }
}
</script>

<style lang="scss" scoped>
.card-patient {
  .btn {
    border-radius: 5px;
  }

  .btn-action {
    button {
      img {
        height: 20px;
      }
    }
  }

  .tab {
    width: 48px;
    height: 4px;
    border-radius: 14px;
  }

  .patient-list {
    padding: 8px 18px 25x;

    .patient-item {
      padding: 12px 0;
      gap: 8px;
      box-shadow: 0px -1px 0px 0px #F2F2F2CC inset;

      .value-wrapper {
        gap: 4px;
      }

      .btn-wrapper {
        bottom: 0;
      }

      &.active {
        color: white;
        background-color: #8CC63F;
      }
    }

    .patient-wrapper {
      width: 36px;
      height: 36px;
    }
  }
}
</style>
