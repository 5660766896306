<template>
  <div class="main-page">
    <div class="container">
      <app-bar
        title="Home Service"
        previousLink="/home-service/pilih-layanan"/>
      <div class="row">
        <div class="col-md-8">
            <div class="row" style="padding-top: 2%">
                <div class="col-md-6">
                    <div class="col py-2">
                        <label class="form-label text-gray-3 fm-nunito fst-normal fw-600 fs-14 lh-14">
                          Nama Pasien
                        </label>
                        <input
                          v-model="selectedMember.nama"
                          name="patient"
                          type="text"
                          class="form-control form-input text-placeholder fm-nunito fst-normal fw-normal fs-12 bg-form-input pt-3 pb-3 ps-3 pe-2 border-white"
                          readonly
                        >
                    </div>
                    <div class="col py-2">
                      <router-link
                        class="btn btn-add bg-blue-soft-pastel p-2 w-100 text-primary m-0 fm-nunito fst-normal fw-600 fs-14 lh-19"
                        data-bs-toggle="modal123"
                        data-bs-target="#modalAddPatient"
                        :to="{
                          query: { modalPatient: 'show' }
                        }"
                      >
                        Saya ingin daftarkan orang lain
                      </router-link>
                    </div>

                    <div class="col py-2">
                        <label class="form-label text-gray-3 fm-nunito fst-normal fw-600 fs-14 lh-14">
                          Tanggal Home Service
                        </label>
                        <input type="date" v-model="cart.tgl_hs" class="form-control form-input fm-nunito fst-normal fw-normal fs-12 bg-form-input pt-3 pb-3 ps-3 pe-2 border-white" required>
                    </div>

                    <div class="col py-2">
                        <label class="form-label text-gray-3 fm-nunito fst-normal fw-600 fs-14 lh-14">
                          Jam Home Service
                        </label>
                        <input type="time" v-model="cart.jam_hs" class="form-control form-input fm-nunito fst-normal fw-normal fs-12 bg-form-input pt-3 pb-3 ps-3 pe-2 border-white" required>
                    </div>

                    <div class="col py-2">
                        <label class="form-label text-gray-3 fm-nunito fst-normal fw-600 fs-14 lh-14">
                            Alamat Lengkap
                        </label>
                        <textarea
                          v-model="cart.alamat_lengkap_hs"
                          name="alamat_lengkap_hs"
                          class="form-control form-input text-placeholder fm-nunito fst-normal fw-normal fs-12 bg-form-input pt-3 pb-3 ps-3 pe-2 border-white"
                          placeholder="Alamat lengkap Anda ..."
                        ></textarea>
                    </div>

                    <div class="col py-2">
                        <label class="form-label text-gray-3 fm-nunito fst-normal fw-600 fs-14 lh-14">
                            Catatan
                        </label>
                        <textarea
                          v-model="cart.ap_catatan"
                          name="note"
                          class="form-control form-input text-placeholder fm-nunito fst-normal fw-normal fs-12 bg-form-input pt-3 pb-3 ps-3 pe-2 border-white"
                          placeholder="Catatan untuk petugas ..."
                        ></textarea>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="col py-2">
                      <label class="form-label text-gray-3 fm-nunito fst-normal fw-600 fs-14 lh-14">
                          Metode Pembayaran
                      </label>
                      <div class="d-flex">
                        <div class="w-50">
                          <div class="pe-2">
                            <div
                              @click="cart.status_bayar = 0"
                              class="btn btn-gender fm-nunito fst-normal fw-600 fs-12 lh-16"
                              :class="{ 'active':cart.status_bayar === 0 }"
                            >
                              Pembayaran Online
                            </div>
                          </div>
                        </div>
                        <!-- <div class="w-50">
                          <div class="ps-2 d-flex justify-content-end">
                            <div
                              @click="cart.status_bayar = 1"
                              class="btn btn-gender fm-nunito fst-normal fw-600 fs-12 lh-16"
                              :class="{ 'active':cart.status_bayar === 1 }"
                            >
                              Pembayaran Offline
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                    <div class="col py-2">
                      <label class="form-label text-gray-3 fm-nunito fst-normal fw-600 fs-14 lh-14">
                          Hasil Cetak
                      </label>
                      <div class="d-flex">
                        <div class="w-50">
                          <div class="pe-2">
                            <div
                              @click="handlePrintOut(0)"
                              class="btn btn-gender fm-nunito fst-normal fw-600 fs-12 lh-16"
                              :class="{ 'active':cart.print_out === 0 }"
                            >
                              Tanpa Dicetak
                            </div>
                          </div>
                        </div>
                        <div class="w-50">
                          <div class="ps-2 d-flex justify-content-end">
                            <div
                              @click="handlePrintOut(1)"
                              class="btn btn-gender fm-nunito fst-normal fw-600 fs-12 lh-16"
                              :class="{ 'active':cart.print_out === 1 }"
                            >
                              Dicetak
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="cart.print_out === 1" class="col py-2">
                      <label class="form-label text-gray-3 fm-nunito fst-normal fw-600 fs-14 lh-14">
                          Metode Pengiriman
                      </label>
                      <div class="d-flex">
                        <div class="w-50">
                          <div class="pe-2">
                            <div
                              @click="handlePengiriman(0)"
                              class="btn btn-gender fm-nunito fst-normal fw-600 fs-12 lh-16"
                              :class="{ 'active':cart.dikirim_status === 0 }"
                            >
                              Ambil Sendiri
                            </div>
                          </div>
                        </div>
                        <div class="w-50">
                          <div class="ps-2 d-flex justify-content-end">
                            <div
                              @click="handlePengiriman(1)"
                              class="btn btn-gender fm-nunito fst-normal fw-600 fs-12 lh-16"
                              :class="{ 'active':cart.dikirim_status === 1 }"
                            >
                              Dikirim
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="cart.print_out === 1 && cart.dikirim_status === 1" class="col py-2">
                      <label class="form-label text-gray-3 fm-nunito fst-normal fw-600 fs-14 lh-14">
                          Alamat Pengiriman
                      </label>
                      <textarea
                          v-model="cart.dikirim_alamat"
                          name="alamat"
                          class="form-control form-input text-placeholder fm-nunito fst-normal fw-normal fs-12 bg-form-input pt-3 pb-3 ps-3 pe-2 border-white"
                          placeholder="Alamat pengiriman hasil cetak"
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="booking-summary pb-5 pb-md-0 mb-4">
                <div class="card card-summary-order">
                    <div class="card-body pb-1">
                        <div class="d-flex flex-row align-items-center justify-content-between py-2">
                            <div class="bd-highlight">
                            <p class="fst-normal text-gray-1 fw-600 fs-14 mb-0 fm-poppins">Ringkasan Pemesanan</p>
                            </div>
                            <div class="bd-highlight">
                                <button class="btn btn-danger text-white" @click="clearCart">
                                    <p class="fst-normal mb-0 fw-600 fs-14 fm-nunito">Hapus Semua</p>
                                </button>
                            </div>
                        </div>

                        <!-- list cart -->
                        <div class="group d-flex flex-column">
                            <div v-for="(summary) in Object.keys(summaries)" :key="summary">
                              <!-- <div class="bd-highlight py-2">
                                  <p class="fst-normal text-gray-1 fw-600 fs-14 mb-0 fm-poppins">
                                    {{ summary }}
                                  </p>
                              </div> -->
                              <div
                                v-for="order in summaries[summary]"
                                :key="order"
                                class="bd-highlight py-2 row"
                              >
                                <div class="col-1 my-auto">
                                  <button class="btn p-0" @click="removeFromCart(order)">
                                    <i class="text-danger ri-indeterminate-circle-line align-middle"></i>
                                  </button>
                                </div>
                                <div class="col fst-normal text-gray-3 fs-14 my-auto fm-nunito">
                                  {{ order.nama }} <br>
                                  <span class="small text-warning">Biaya tambahan: Rp. {{ parseInt(order.biaya_tambahan_hs).toLocaleString('id-ID') }}</span>
                                </div>
                                <div class="col fst-normal text-gray-3 fs-14 my-auto fm-nunito text-end">
                                  Rp. {{ parseInt(order.harga)?.toLocaleString('id-ID') }}
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="group d-flex flex-column">
                            <div class="divider pt-3">
                                <hr class="border bg-gray-6 my-0">
                            </div>
                            <div class="bd-highlight py-2">
                                  <p v-if="diskonPenggunaBaru.nominal > 0 || diskon" class="fst-normal text-gray-1 fw-600 fs-14 mb-0 fm-poppins">
                                    Diskon
                                  </p>

                                <div v-if="diskonPenggunaBaru.nominal > 0" class="d-flex flex-row align-items-center justify-content-between py-2">
                                    <div class="bd-highlight">
                                        <p class="text-gray-3 fs-14 fm-nunito lh-19 mb-0">Diskon Pengguna Baru</p>
                                    </div>
                                    <div class="bd-highlight">
                                        <p class="text-gray-3 fs-14 fm-nunito mb-0 text-decoration-line-through">
                                          Rp. {{ diskonPenggunaBaru.nominal.toLocaleString('id-ID') }}
                                        </p>
                                    </div>
                                </div>
                                <div v-if="diskon" class="d-flex flex-row align-items-center justify-content-between py-2">
                                    <div class="bd-highlight">
                                        <p class="text-gray-3 fs-14 fm-nunito lh-19 mb-0">Voucher "{{ cart.kode_voucher }}"</p>
                                    </div>
                                    <div class="bd-highlight">
                                        <p class="text-gray-3 fs-14 fm-nunito mb-0 text-decoration-line-through">
                                          Rp. {{ diskon.toLocaleString('id-ID') }}
                                        </p>
                                    </div>
                                </div>
                                <div class="d-flex flex-row align-items-center justify-content-between py-2">
                                    <div class="bd-highlight">
                                        <p class="text-gray-3 fs-14 fm-nunito lh-19 mb-0">Sub Total</p>
                                    </div>
                                    <div class="bd-highlight">
                                        <p class="text-gray-1 fs-14 fw-600 fm-nunito mb-0" :key="subTotal">
                                          Rp. {{ (subTotal - biayaOngkir).toLocaleString('id-ID') }}
                                        </p>
                                    </div>
                                </div>

                                <div class="divider">
                                    <hr class="border bg-gray-6 my-0">
                                </div>

                                <p v-if="cart.dikirim_status === 1 || parseInt(biayaTambahan) || parseInt(selectedBranch.biaya_hs)" class="fst-normal text-gray-1 fw-600 fs-14 mb-0 fm-poppins mt-2">
                                    Biaya Tambahan
                                </p>

                                <div class="d-flex flex-row align-items-center justify-content-between py-2">
                                    <div class="bd-highlight">
                                        <p class="text-gray-3 fs-14 fm-nunito lh-19 mb-0">Total Biaya Tambahan</p>
                                    </div>
                                    <div class="bd-highlight">
                                        <p class="text-gray-3 fs-14 fm-nunito mb-0">
                                          Rp. {{ cart.biaya_tambahan_hs.toLocaleString('id-ID') }}
                                        </p>
                                    </div>
                                </div>

                                <div class="d-flex flex-row align-items-center justify-content-between py-2">
                                    <div class="bd-highlight">
                                        <p class="text-gray-3 fs-14 fm-nunito lh-19 mb-0">Biaya Home Service</p>
                                    </div>
                                    <div class="bd-highlight">
                                        <p class="text-gray-3 fs-14 fm-nunito mb-0">
                                          Rp. {{ parseInt(selectedBranch.biaya_hs).toLocaleString('id-ID') }}
                                        </p>
                                    </div>
                                </div>

                                <div v-if="cart.dikirim_status === 1" class="d-flex flex-row align-items-center justify-content-between py-2">
                                    <div class="bd-highlight">
                                        <p class="text-gray-3 fs-14 fm-nunito lh-19 mb-0">Ongkos Kirim</p>
                                    </div>
                                    <div class="bd-highlight">
                                        <p class="text-gray-3 fs-14 fm-nunito mb-0">
                                          Rp. 15.000
                                        </p>
                                    </div>
                                </div>

                                <div class="divider">
                                    <hr class="border bg-gray-6 my-0">
                                </div>

                                <div class="d-flex flex-row align-items-center justify-content-between py-2">
                                    <div class="bd-highlight">
                                        <p class="text-gray-3 fs-14 fm-nunito lh-19 mb-0">Total Tagihan</p>
                                    </div>
                                    <div class="bd-highlight">
                                        <p class="text-gray-1 fs-14 fw-600 fm-nunito mb-0">
                                          Rp. {{ total.toLocaleString('id-ID') }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <router-view />

                            <div class="bottom-bar p-3 p-md-0 text-center">
                              <span class="text-error fm-nunito fs-16 fw-600">{{ errorMessage }}</span>

                              <div class="voucher-group mb-2 d-block">
                                <i class="ri-coupon-line text-secondary"></i>
                                <input
                                  v-model="cart.kode_voucher"
                                  type="text"
                                  class="form-control"
                                  placeholder="Masukkan voucher"
                                  @change="countVoucher"
                                >
                                <button
                                  class="btn btn-sm btn-danger text-w me-5 me-md-0"
                                  @click="clearVoucher">
                                  <i class="text-white ri-delete-bin-6-line"></i>
                                </button>
                                <router-link
                                  :to="{ name: 'PatientScanVoucher' }"
                                  class="btn btn-sm btn-primary text-w d-md-none pt-1">
                                  <i class="ri-qr-code-line"></i>
                                </router-link>
                              </div>

                              <div class="d-flex mb-md-3">
                                <!-- <button
                                  class="btn bg-secondary me-1 fw-600 fs-14 w-100 py-2 text-white
                                    d-flex justify-content-center align-items-center gap-1"
                                  @click="$router.push({ name: 'Home' })"
                                  :disabled="loading.order || loading.voucher"
                                >
                                  <i class="ri-add-line"></i>
                                  Tambah Layanan
                                </button> -->
                                <button
                                  class="btn bg-primary ms-1 fw-600 fs-14 w-100 py-2 text-white
                                    d-flex justify-content-center align-items-center gap-1"
                                  @click="openConfirmation"
                                  :disabled="loading.order || loading.voucher"
                                >
                                  {{ (loading.order) ? 'Tunggu..' : 'Proses' }}
                                  <i class="ri-arrow-right-line"></i>
                                </button>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>

      <!-- divider -->
      <div class="d-md-none my-3 py-3"></div>
    </div>
    <AddPatient/>
    <Anamnesa v-model:statusAnamnesa="statusAnamnesa"/>
  </div>
  <!-- Modal -->
  <div class="modal fade"
  id="modal-confirmation"
  tabindex="-1"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body text-center d-flex flex-column py-3">
            <h5 class="modal-title" id="exampleModalLabel">Konfirmasi</h5>
            <p v-for="confirmationMessage in confirmationMessages" :key="confirmationMessage.val">
              {{ confirmationMessage.val }}
            </p>
            <div class="group-button pb-2 pt-3">
              <button
                type="button"
                class="btn btn-secondary text-white w-25 me-1" data-bs-dismiss="modal">Tidak</button>
              <button
                @click="submit"
                type="button" class="btn text-white btn-danger w-25 ms-1">
                Ya
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
</template>

<script>
import { nextTick, onMounted, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import AddPatient from '@/components/home/service/covid/AddPatient'
import Anamnesa from '@/components/home/service/covid/Anamnesa'
import { getConfirmation, selectedMember } from '@/core/service'
import {
  cart, diskon, getCart, clearCart,
  clearVoucher, countVoucher, loading,
  removeFromCart, submitOrder, selectedBranch,
  biayaTambahan, diskonPenggunaBaru
} from '@/core/homeService'
import { user, kode_promo } from '@/core/auth'
import AppBar from '@/components/AppBar.vue'
import { showNotif } from '@/core/utility'
import { Modal } from 'bootstrap'
import { title } from '@/core/page'

let modal = null

export default {
  components: {
    AddPatient,
    AppBar,
    Anamnesa
  },
  setup () {
    // Prepare variable
    const errorMessage = ref('')
    const router = useRouter()
    const route = useRoute()
    const statusAnamnesa = ref(false)
    const confirmationMessages = ref([])
    const biayaOngkir = ref(0)

    // Prepare computed
    const subTotal = computed(() => {
      return parseInt(cart.value.ap_nombayar) - parseInt(cart.value.nominal_voucher)
    })

    const total = computed(() => {
      return subTotal.value + parseInt(selectedBranch.value.biaya_hs) + parseInt(cart.value.biaya_tambahan_hs)
    })

    const summaries = computed(() => {
      const temp = []
      cart.value.arr_paket.map((data) => {
        const nama_group = data.group
        if (!temp[nama_group]) {
          temp[nama_group] = [data]
        } else {
          temp[nama_group].push(data)
        }
      })
      return temp
    })

    onMounted(async () => {
      await getCart()
      selectedMember.nama = user.value.pas_nama
      selectedMember.pas_id_periksa = user.value.pas_id

      // kalau sudah pakai voucher langsung hitung yaaaa
      cart.value.kode_voucher = kode_promo.value.kode_voucher

      nextTick(() => {
        modal = new Modal(
          document.querySelector('#modalAddPatient'), {
            backdrop: 'static'
          }
        )

        if (route.query.modalPatient === 'show') {
          modal.show()
        }

        title.value = 'Checkout'
      })
      countVoucher() // Recheck + recount voucher
    })

    const submit = async () => {
      try {
        modal.hide()
        const result = await submitOrder()
        router.push({
          name: 'Summary',
          params: {
            id_order: result.data.kode_daftar
          }
        })
      } catch (error) {
        errorMessage.value = error.response.data.message
      }
    }

    const handlePrintOut = (value) => {
      cart.value.print_out = value

      // Reset data lainnya jika tidak jadi cetak
      if (value === 0) {
        // Reset ongkit
        if (cart.value.dikirim_status !== 0) {
          cart.value.ap_nombayar -= 15000
          biayaOngkir.value = 0
        }
        // Reset data selanjutnya
        cart.value.dikirim_status = 0
        cart.value.dikirim_alamat = ''
      }
    }

    const handlePengiriman = (value) => {
      // Reset data lainnya jika tidak jadi cetak
      if (cart.value.dikirim_status !== value) {
        if (value === 0) {
          cart.value.dikirim_alamat = ''

          // Reset ongkit
          cart.value.ap_nombayar -= 15000
          biayaOngkir.value = 0
        } else { // Tambahkan 15.000 sbg ongkos kirim flat
          cart.value.ap_nombayar += 15000
          biayaOngkir.value = 15000
        }
      }
      cart.value.dikirim_status = value
    }

    const openConfirmation = async () => {
      errorMessage.value = ''
      // Tangal periksa itu wajib ya
      if (!cart.value.tgl_hs) {
        errorMessage.value = 'Silahkan pilih tanggal Home Service'
      }

      // Tanggal periksa harus >= hari ini
      if (new Date(cart.value.tgl_hs).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) {
        errorMessage.value = 'Tanggal periksa harus lebih dari hari ini'
      }

      // Jam periksa itu wajib ya
      if (!cart.value.jam_hs) {
        errorMessage.value = 'Silahkan pilih jam Home Service'
      }

      // Alamat lengkap itu wajib ya
      if (!cart.value.alamat_lengkap_hs) {
        errorMessage.value = 'Silahkan isi alamat lengkap Anda'
      }

      // jika bayar online dan total bayar cuma 0
      // maka jangan, nanti error midtrans soalnya
      if (cart.value.status_bayar === 0 && total.value < 1) {
        errorMessage.value = 'Minimal pembelian Rp.1'
      }

      // Alamat wajib jika dikirim
      if (cart.value.print_out === 1 && cart.value.dikirim_status === 1 && !cart.value.dikirim_alamat) {
        errorMessage.value = 'Alamat harus diisi jika ingin dikirim.'
      }

      if (confirmationMessages.value.length < 1) {
        const confirmation = await getConfirmation()
        if (confirmation.status) {
          confirmationMessages.value = confirmation.message
        } else {
          errorMessage.value = confirmation.message
        }
      }

      if (errorMessage.value) {
        // Trigger toast
        showNotif({
          status: 'error',
          message: errorMessage.value
        })
        return
      }

      modal = new Modal(
        document.querySelector('#modal-confirmation'), {
          backdrop: 'static'
        }
      )
      modal.show()
    }

    return {
      cart,
      user,
      selectedMember,
      loading,
      errorMessage,
      submit,
      total,
      diskon,
      clearCart,
      statusAnamnesa,
      handlePrintOut,
      handlePengiriman,
      summaries,
      removeFromCart,
      clearVoucher,
      countVoucher,
      openConfirmation,
      confirmationMessages,
      selectedBranch,
      biayaTambahan,
      diskonPenggunaBaru,
      subTotal,
      biayaOngkir
    }
  },
  watch: {
    '$route.query': function (query) {
      if (query.voucher) {
        cart.value.kode_voucher = query.voucher
      }

      if (modal) {
        if (query.modalPatient === 'show') {
          modal.show()
          console.log('show')
        } else {
          modal.hide()
          console.log('hide modal')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom-bar {
  @media (max-width: 520px) {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid rgb(240, 240, 240);
  }
}

.voucher-group {
  position: relative;

  i.ri-coupon-line {
    position: absolute;
    left: .5rem;
    top: 50%;
    z-index: 39;
    transform: translateY(-50%);
  }

  input {
    padding: .7rem 2rem;
  }

  .btn {
    position: absolute;
    right: .5rem;
    top: 50%;
    transform: translateY(-50%);
    padding: .2rem .5rem;
  }
}

.input-time {
    background: #ECFBFF;
    border-radius: 6px;
    width: 108px;
}

.input-time.active {
    background: #007A98;
}

.input-time.active p {
    color: #FFFFFF !important;
}

.radio-button {
    display: none;
}

.radio-button:checked {
    background: red;
}

.form-control.form-input {
    border-radius: 8px;
}

.btn-add {
    border: 1px dashed #007A98;
    box-sizing: border-box;
    border-radius: 8px !important;
}

.card-summary-order {
    background: #FFFFFF;
    box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.05);
    border-radius: 10px !important;
    border: none;
}

.btn-gender {
  border-radius: 8px;
  background: #EFF0F7;
  color: #4E4B66;
  width: 100%;
}

.btn-gender.active {
  background: #FF9D0A;
  color: #FFFFFF
}

.rounded-xl {
  border-radius: 10px;
}
</style>
